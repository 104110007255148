import styles from './Courses.module.scss';
import Fade from 'react-reveal/Fade';
import Dropdown from 'react-dropdown';
import { Link } from 'react-scroll';
import { NavLink } from "react-router-dom";
import 'react-dropdown/style.css';
import axios from "axios";
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import DatePicker from 'react-date-picker';
import GoTop from '../goTop/GoTop';

const jeugdOptions = ['Jeugd 1 (5-7j)', 'Jeugd 2 (8-9j)', 'Jeugd 3 (10-11j)', 'Jeugd 4 (11-15j)', 'Jeugd 5 (16j+)'];
const koppelOptions = ['Koppeldans beginners', 'Koppeldans 2', 'Koppeldans 3', 'Koppeldans 4', 'Koppeldans 5', 'Koppeldans 6'];

export interface CourseProps {
    _id?: string;
    title: string;
    pricing: string;
    description: string;
    imgUrl: string;
}

function Courses(props) {

    const [chosenCourse, setChosenCourse] = useState("");
    const [courses, setCourses] = useState([] as CourseProps[]);
    const [coursesOptions, setCoursesOptions] = useState([] as string[]);
    const [jeugdOption, setJeugdOption] = useState("");
    const [koppelOption, setKoppelOption] = useState("");
    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [chosenDate, setChosenDate] = useState(new Date());
    const [registration, setRegistration] = useState({ firstName: "", lastName: "", street: "", number: "", city: "", dateOfBirth: "", bus: "", email: "", postalCode: "", country: "", phoneNo: "", nationality: "", sex: "" });
    // const [scrollPosition, setSrollPosition] = useState(0);
    const [showGoTop, setshowGoTop] = useState(styles.goTopHidden);



    //SCROLL LISTENER
    useEffect(() => {
        window.addEventListener("scroll", handleVisibleButton);
        axios.get('/courses/').then(res => {
            if (res.data && res.data.length > 0) {
                const courses: CourseProps[] = res.data;
                setCourses(courses);
                setCoursesOptions(courses.map(m => m?.title));
                const urlParams = new URLSearchParams(window.location.search);
                const course = urlParams.get('course');
                if (course) {
                    setChosenCourse(course);
                    const hiddenAnchor = document.createElement("a");
                    hiddenAnchor.setAttribute('href', "#inschrijven");
                    hiddenAnchor.setAttribute('class', styles.hidden);
                    const parent = document.getElementById("hiddenAnchor");
                    parent?.append(hiddenAnchor);
                    hiddenAnchor.click();
                }
            }
        })
    }, []);

    const setRegistrationProp = (prop, value) => {
        let aRegistration = { ...registration };
        aRegistration[prop] = value;
        setRegistration(aRegistration);
        setMessage("");
        setMessageClass("");
    }

    const handleVisibleButton = () => {
        const scrollPosition = window.scrollY;
        // setSrollPosition(scrollPosition);

        if (scrollPosition > 50) {
            return setshowGoTop(styles.goTop);
        } else if (scrollPosition < 50) {
            return setshowGoTop(styles.goTopHidden);
        }
    };

    const handleScrollUp = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const register = (registration) => {
        registration.danceOption = "";
        registration.chosenDance = chosenCourse;
        if (registration.chosenDance === "Koppeldans") {
            registration.danceOption = koppelOption;
        }
        if (registration.chosenDance === "Jeugd") {
            registration.danceOption = jeugdOption;
        }
        try {
            axios.post('/registrations/', registration).then(res => {
                let msg = "";
                let messageClass = "";
                if (res.status === 200) {
                    msg = "Uw inschrijving werd geregistreerd! U kan uw betaling voltooien op de eerste les, of over schrijven op volgend rekeningnummer: BE23 9793 8826 0891";
                    messageClass = styles.success;

                } else {
                    msg = "Oeps! Er ging iets mis. Probeer het nog een keer, of neem contact met ons op.";
                    messageClass = styles.error;
                }
                setMessage(msg);
                setMessageClass(messageClass);
            });
        } catch (err) {
            console.error(err);
        }
    }

    const updateDate = (e: Date) => {
        let dateString = props.datahelper.formatDate(e);
        let reg = { ...registration };
        reg.dateOfBirth = dateString;
        setChosenDate(e);
        setRegistration(reg);
    }

    const _outputCourses = () => {
        let output: React.ReactElement[] = [];
        for (let i = 0; i < courses.length; i++) {
            output.push(
                <section key={i} className={i % 3 === 0 ? styles.secondCourseSection : (i % 3 === 1 ? styles.thirdCourseSection : styles.firstCourseSection)}>
                    <div className={styles.displayFlexColumn}>
                        <Fade duration={1500} top distance={"100px"}>
                            <div className="container">
                                <div className="row">
                                    <div className={i % 2 === 0 ? 'col-lg-8 ' + styles.mobileFlexOrder1 : 'col-lg-8 offset-4'}>
                                        <h4 style={{ textAlign: (i % 2 === 0 ? 'right' : 'left') }}>{courses[i]?.title}</h4>
                                    </div>
                                    {i % 2 === 0 ?
                                        <>
                                            <div className={"col-lg-8 " + styles.mobileFlexOrder3} style={{ textAlign: "right" }}>
                                                <div dangerouslySetInnerHTML={{ __html: courses[i]?.description }}></div>
                                                {courses[i].title !== "Senioren" &&
                                                    <div dangerouslySetInnerHTML={{ __html: courses[i]?.pricing }}></div>
                                                }


                                                {(courses[i].title === "Koppeldans" || courses[i].title === "Jeugd") &&
                                                    <p><NavLink className={styles.extraUrl} to="uurrooster">Bekijk het uurrooster</NavLink> om te raadplegen wanneer deze cursussen plaatsvinden!</p>
                                                }
                                                {courses[i].title !== "Senioren" &&
                                                    <Link to="inschrijven" onClick={() => setChosenCourse(courses[i]?.title)} spy={true} smooth={true} offset={50} duration={500} style={{ color: i % 3 === 0 ? 'black' : (i % 3 === 1 ? '#e8e1cb' : 'white'), borderColor: i % 3 === 0 ? 'black' : (i % 3 === 1 ? '#e8e1cb' : 'white') }}>NAAR INSCHRIJVEN!</Link>
                                                }
                                            </div>
                                            <div className={"col-lg-4 " + styles.mobileFlexOrder2}>
                                                <img alt="course" className={styles.sectionImg} src={courses[i]?.imgUrl} />
                                            </div>
                                        </> :
                                        <>
                                            <div className="col-lg-4">
                                                <img alt="course" className={styles.sectionImg} src={courses[i]?.imgUrl} />
                                            </div>
                                            <div className="col-lg-8">
                                                <div dangerouslySetInnerHTML={{ __html: courses[i]?.description }}></div>
                                                <div dangerouslySetInnerHTML={{ __html: courses[i]?.pricing }}></div>
                                                {(courses[i].title === "Koppeldans" || courses[i].title === "Jeugd") &&
                                                    <p><NavLink className={styles.extraUrl} to="uurrooster">Bekijk het uurrooster</NavLink> om te raadplegen wanneer deze cursussen plaatsvinden!</p>
                                                }
                                                {courses[i].title !== "Senioren" &&
                                                    <Link to="inschrijven" onClick={() => setChosenCourse(courses[i]?.title)} spy={true} smooth={true} offset={50} duration={500} style={{ color: i % 3 === 0 ? 'black' : (i % 3 === 1 ? '#e8e1cb' : 'white'), borderColor: i % 3 === 0 ? 'black' : (i % 3 === 1 ? '#e8e1cb' : 'white') }}>NAAR INSCHRIJVEN!</Link>
                                                }
                                            </div>

                                        </>}
                                </div>
                            </div>
                        </Fade>

                    </div>
                </section>
            )
        }
        return output;
    }

    return (
        <div className={styles.courses}>
            <div id="hiddenAnchor"></div>
            <GoTop showGoTop={showGoTop} scrollUp={handleScrollUp} />
            <section className={styles.backgroundImage}>
                <div className={styles.overlay}></div>
            </section>
            <div className={styles.row}>
                <div className={styles.headerWrapper}>
                    <section className={styles.header}>
                        <Fade duration={1500} top distance={"100px"}>
                            <div className="container">
                                <div className="row">
                                    <div className={"col-lg-12"}>
                                        <h2>Kom jij ook bij ons dansen?</h2>
                                        <i style={{ fontSize: "1.2em" }}>Ontdek ons uitgebreid aanbod aan cursussen</i>
                                        <p style={{ fontWeight: 400 }}>€ 10,- lidgeld te betalen voor een volledig dansjaar. Voor de cursussen koppeldans en jeugd werd dit reeds in de prijs verrekend.</p>
                                        <Link to="inschrijven" spy={true} smooth={true} offset={50} duration={500}>NAAR INSCHRIJVEN!</Link>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </section>
                    {_outputCourses()}


                    <section className={styles.bgDarkBlock}>
                        <span id="inschrijven" className={styles.inschrijvenAnchor}></span>
                        <section className={styles.tierList}>
                            <div className={styles.container}>

                                <Fade duration={1500} bottom distance={"100px"}>
                                    <div className={styles.row}>
                                        <div className={styles.colLg12}>
                                            <div className={[styles.card, styles.bodyBg1].join(' ')}>
                                                <div className={styles.cardHeader}>
                                                    <p>Uw gekozen cursus: <b>{chosenCourse}</b></p>
                                                    <h3>Schrijf je in door onderstaand formulier in te vullen</h3>
                                                </div>
                                                <form className={styles.cardbody}>
                                                    <div className={[styles.row, styles.formGroup].join(' ')}>
                                                        <div className={styles.colLg4}>
                                                            <div className={styles.placeholder} data-placeholder="Danscursus *">
                                                                <Dropdown options={coursesOptions} onChange={(e) => {
                                                                    setChosenCourse(e.value);
                                                                    setMessage("");
                                                                    setMessageClass("");
                                                                    setKoppelOption("");
                                                                    setJeugdOption("");
                                                                }} value={chosenCourse} />
                                                            </div>
                                                        </div>
                                                        {(chosenCourse === "Jeugd" || chosenCourse === "Koppeldans") ?
                                                            <>
                                                                <div className={styles.colLg4}>
                                                                    <div className={styles.placeholder} data-placeholder="Uw dansgroep *">
                                                                        <Dropdown options={chosenCourse === "Jeugd" ? jeugdOptions : koppelOptions} onChange={(e) => {
                                                                            chosenCourse === "Jeugd" ? setJeugdOption(e.value) : setKoppelOption(e.value);
                                                                            setMessage("");
                                                                            setMessageClass("");
                                                                        }
                                                                        } value={chosenCourse === "Jeugd" ? jeugdOption : koppelOption} placeholder="Selecteer een dansgroep" />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div className={styles.colLg6}></div>
                                                            </>
                                                        }
                                                    </div>


                                                    <div className={[styles.row, styles.formGroup].join(' ')}>
                                                        <div className={styles.colLg4}>
                                                            <div className={styles.placeholder} data-placeholder="Voornaam *">
                                                                <input value={registration.firstName} onChange={(e) => setRegistrationProp("firstName", e.target.value)} type="text" className={styles.formControl} />
                                                            </div>
                                                        </div>
                                                        <div className={styles.colLg4}>
                                                            <div className={styles.placeholder} data-placeholder="Naam *">
                                                                <input value={registration.lastName} onChange={(e) => setRegistrationProp("lastName", e.target.value)} type="text" className={styles.formControl} />
                                                            </div>
                                                        </div>
                                                        <div className={styles.colLg4}>
                                                            <div className={styles.placeholder} data-placeholder="E-mailadres *">
                                                                <input value={registration.email} onChange={(e) => setRegistrationProp("email", e.target.value)} type="text" className={styles.formControl} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={[styles.row, styles.formGroup].join(' ')}>
                                                        <div className={styles.colLg4}>
                                                            <div className={styles.placeholder} data-placeholder="Straatnaam *">
                                                                <input value={registration.street} onChange={(e) => setRegistrationProp("street", e.target.value)} type="text" className={styles.formControl} />
                                                            </div>
                                                        </div>
                                                        <div className={styles.colLg1}>
                                                            <div className={styles.placeholder} data-placeholder="Huisnummer *">
                                                                <input value={registration.number} onChange={(e) => setRegistrationProp("number", e.target.value)} type="text" className={styles.formControl} />

                                                            </div>
                                                        </div>
                                                        <div className={styles.colLg1}>
                                                            <div className={styles.placeholder} data-placeholder="Busnummer">
                                                                <input value={registration.bus} onChange={(e) => setRegistrationProp("bus", e.target.value)} type="text" className={styles.formControl} />
                                                            </div>
                                                        </div>
                                                        <div className={styles.colLg2}>
                                                            <div className={styles.placeholder} data-placeholder="Postcode *">
                                                                <input value={registration.postalCode} onChange={(e) => setRegistrationProp("postalCode", e.target.value)} type="text" className={styles.formControl} />

                                                            </div>
                                                        </div>
                                                        <div className={styles.colLg2}>
                                                            <div className={styles.placeholder} data-placeholder="Gemeente *">
                                                                <input value={registration.city} onChange={(e) => setRegistrationProp("city", e.target.value)} type="text" className={styles.formControl} />

                                                            </div>
                                                        </div>
                                                        <div className={styles.colLg2}>
                                                            <div className={styles.placeholder} data-placeholder="Land *">
                                                                <input value={registration.country} onChange={(e) => setRegistrationProp("country", e.target.value)} type="text" className={styles.formControl} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={[styles.row, styles.formGroup].join(' ')}>
                                                        <div className={styles.colLg2}>
                                                            <div className={styles.placeholder} data-placeholder="Geslacht *">
                                                                <Dropdown options={[{ label: "M", value: "M", }, { label: "V", value: "V", }, { label: "X", value: "X", }]} onChange={(e) => setRegistrationProp("sex", e.value)} value={registration.sex} placeholder="Selecteer een dansgroep" />
                                                            </div>
                                                        </div>
                                                        <div className={styles.colLg1}>
                                                            <div className={styles.placeholder} data-placeholder="Nationaliteit *">
                                                                <input value={registration.nationality} onChange={(e) => setRegistrationProp("nationality", e.target.value)} type="text" className={styles.formControl} />
                                                            </div>
                                                        </div>
                                                        <div className={styles.colLg2}>
                                                            <div id="datePicker">
                                                                <div className={styles.placeholder} data-placeholder="Geboortedatum *">
                                                                    <DatePicker onChange={(e) => {
                                                                        updateDate(e);
                                                                        setMessage("");
                                                                        setMessageClass("");
                                                                    }} locale={"nl-BE"} value={chosenDate} />
                                                                </div>
                                                            </div>
                                                            {/* <input value={registration.dateOfBirth} onChange={(e) => setRegistrationProp("dateOfBirth", e.target.value)} placeholder="Geboortedatum" type="text" className={styles.formControl} /> */}
                                                        </div>
                                                        <div className={styles.colLg4}>
                                                            <div className={styles.placeholder} data-placeholder="Telefoonnummer *">
                                                                <input value={registration.phoneNo} onChange={(e) => setRegistrationProp("phoneNo", e.target.value)} type="text" className={styles.formControl} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <i className={styles.privacyDisclaimer}>Ik verklaar mij akkoord dat Dansclub Happy Dancer deze gegevens en foto's mag gebruiken voor het ledenbestand en reclamedoeleinden. Dit i.v.m. de wet op privacy van 25 mei 2018. </i>
                                                    {message &&
                                                        <p><span className={messageClass}>{message}</span></p>}
                                                    <span onClick={() => {
                                                        if ((jeugdOption === "" && chosenCourse === "Jeugd") || (koppelOption === "" && chosenCourse === "Koppeldans")) {
                                                            Swal.fire({
                                                                title: 'Geen dansgroep geselecteerd',
                                                                text: 'Bij het inschrijven voor de cursus ' + chosenCourse + ' dient u een dansgroep aan te duiden.',
                                                                icon: 'error',
                                                                confirmButtonText: 'OK'
                                                            });
                                                        } else {
                                                            if (registration.firstName &&
                                                                registration.lastName &&
                                                                registration.city &&
                                                                registration.country &&
                                                                registration.dateOfBirth &&
                                                                registration.email &&
                                                                registration.nationality &&
                                                                registration.number &&
                                                                registration.phoneNo &&
                                                                registration.postalCode &&
                                                                registration.sex) {
                                                                if (registration.sex === "") {
                                                                    Swal.fire({
                                                                        title: 'Geen geslacht geselecteerd',
                                                                        text: 'Gelieve een geslacht aan te duiden.',
                                                                        icon: 'error',
                                                                        confirmButtonText: 'OK'
                                                                    });
                                                                } else
                                                                    if (chosenCourse) {
                                                                        register(registration);
                                                                    } else {
                                                                        Swal.fire({
                                                                            title: 'Geen cursus geselecteerd',
                                                                            text: 'Selecteer een cursus om verder te gaan.',
                                                                            icon: 'error',
                                                                            confirmButtonText: 'OK'
                                                                        });
                                                                    }
                                                            } else {
                                                                Swal.fire({
                                                                    title: 'Lege velden',
                                                                    text: 'Zorg dat alle verplichte velden ingevuld zijn!',
                                                                    icon: 'warning',
                                                                    confirmButtonText: 'OK'
                                                                });
                                                            }
                                                            // register(registration);
                                                        }
                                                    }
                                                    } className={styles.button}>INSCHRIJVEN!</span>
                                                    {/* <p>Ons systeem kan momenteel geen registraties maken. Gelieve een mail te sturen naar info@happydancer.be met bovenstaande gegevens en dan verwerken we uw inschrijving. Onze excuses voor het ongemak!</p> */}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        </section>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default Courses;