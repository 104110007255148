import { FunctionComponent } from "react";
import { BlogProps } from "./../Blogs";
import styles from "./Blog.module.scss";

export interface IBlogProps {
    blog: BlogProps;
}

const Blog: FunctionComponent<IBlogProps> = (props) => {

    const outputSubTitle = (blogpost: BlogProps) => {
        const items: string[] = [];
        if (blogpost.location) {
            items.push(blogpost.location);
        }
        if (blogpost.eventDate) {
            items.push(blogpost.eventDate);
        }
        if (blogpost.startingHour) {
            items.push(blogpost.startingHour);
        }

        return items.length > 0 ? <h3><span className={styles.eventFlag}>{items.join(" • ")}</span></h3> : <></>;
    }

    return (
        <>
            {props.blog ?
                <article className={styles.activeBlogPost}>
                    <h1>{props.blog.title}</h1>
                    <>{outputSubTitle(props.blog)}</>
                    <img alt="news" src={props.blog.imgUrl} />
                    <div dangerouslySetInnerHTML={{ __html: props.blog.content }}></div>
                </article>
                : <p>De blog die je probeerde op te zoeken, bestaat niet.</p>}
        </>
    );
}

export default Blog;