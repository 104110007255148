import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import Fade from 'react-reveal/Fade';
import styles from './InstagramFeed.module.scss';

function InstagramFeed(props) {

    const [transform, setTransform] = useState(-1);
    // const [scroll, setScroll] = useState(0);
    const [inframe, setInframe] = useState(false);
    const [videoHover, setVideoHover] = useState(null);
    const [touchPosition, setTouchPosition] = useState(null)


    const handleClick = dir => {
        let feed = document.getElementById("instagramFeed");
        if (feed) {
            let length = props.feed?.length - 1;
            let totalWidth = length * feed.clientHeight;
            let output = transform - (dir * 300) < -1 ? transform - (dir * 300) : -1;
            if ((-1 * totalWidth < output)) {
                setTransform(output);
            } else {
                setTransform(-1 * totalWidth);
            }
        }


    };

    // ...
    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown);
    }

    const handleTouchMove = (e) => {
        const touchDown = touchPosition

        if (touchDown === null) {
            return
        }

        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch

        if (diff > 5) {
            setTransform(transform - 300);
        }

        if (diff < -5 && transform < -300) {
            setTransform(transform + 300);
        }

        setTouchPosition(null)
    }

    const handleHover = inFrame => setInframe(inFrame);
    const handleVideoHover = (id, inFrame) => inFrame ? setVideoHover(id) : setVideoHover(null);

    return (
        <div className={styles.instaFeedWrapper}>
            <span className={styles.mobileButton + " " + styles.onlyMobile} onClick={(e) => handleClick(-1)}>
                <FontAwesomeIcon icon={faAngleLeft as any} />
            </span>
            <div onMouseEnter={() => handleHover(true)} onMouseLeave={() => handleHover(false)} className={styles.feed}>
                {inframe &&
                    <Fade duration={500} bottom>
                        <span onClick={(e) => handleClick(-1)} className={styles.left}>
                            <FontAwesomeIcon icon={faAngleLeft as any} />
                        </span>
                    </Fade>
                }


                <div id="instagramFeed" style={{ transform: "translateX(" + transform + "px)" }} className={styles.instagramFeed} onTouchStart={(e) => handleTouchStart(e)} onTouchMove={handleTouchMove}>
                    {props?.feed?.map((post, i) => {
                        if (post.media_type === "VIDEO") {
                            return (
                                <a key={i} onMouseEnter={() => handleVideoHover(post.id, true)} onMouseLeave={() => handleVideoHover(post.id, false)} href={post.permalink} target="_blank" rel="noreferrer" className={styles.instaVideo}>
                                    {post.id === videoHover ?
                                        <video
                                            controls
                                            muted
                                            autoPlay={true}
                                            loop>
                                            <source src={post.media_url} type="video/mp4" />
                                        </video> :
                                        <video
                                            controls
                                            muted
                                            autoPlay={true}
                                            loop>
                                            <source src={post.media_url} type="video/mp4" />
                                        </video>}
                                    <div style={{ opacity: post.id === videoHover ? 1 : 0 }} className={styles.caption}><p>{post.caption}</p></div>
                                </a>

                            );
                        } else {
                            return (
                                <a key={i.toString()} href={post.permalink} target="_blank" rel="noreferrer" style={{ backgroundImage: "url('" + post.media_url + "')" }} className={styles.instaImage}>
                                    <div className={styles.caption}><p>{post.caption}</p></div>
                                </a>
                            );
                        }
                    }
                    )}
                </div>
                {inframe &&
                    <Fade duration={500} bottom>
                        <span onClick={(e) => handleClick(1)} className={styles.right}>
                            <FontAwesomeIcon icon={faAngleRight as any} />
                        </span>
                    </Fade>
                }
            </div>
            <span onClick={(e) => handleClick(1)} className={styles.mobileButton + " " + styles.mobileButtonRight + " " + styles.onlyMobile}>
                <FontAwesomeIcon icon={faAngleRight as any} />
            </span>
        </div>

    );
}

export default InstagramFeed;