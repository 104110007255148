import { faBars, faCalendar, faCalendarDays, faInfoCircle, faLaptop, faTachometerAlt, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import React from "react";
import styles from "./Dashboard.module.scss";
import profilePic from "./../../assets/images/aboutus1.png";
import { ManageBlogs } from "./manageBlogs/ManageBlogs";
import { DataHelper } from "../../data/DataHelper";
import { ManageEvents } from "./manageEvents/ManageEvents";
import { ManageCourses } from "./manageCourses/ManageCourses";
import { ManageSchedules } from "./manageSchedules/ManageSchedules";


export interface IDashboardProps {
    // setAdmin: (admin: boolean) => void;
}

export interface IDashboardState {
    section: Section;
    mobileMenuClosed: boolean;
}

enum Section {
    Blogs,
    Events,
    Course,
    Schedule,
    None
}

const dataHelper: DataHelper = new DataHelper();

export class Dashboard extends React.Component<IDashboardProps, IDashboardState> {

    constructor(props: IDashboardProps) {
        super(props);
        this.state = { section: Section.None, mobileMenuClosed: true };
    }

    public componentDidMount() {
        let elements = document.getElementsByTagName("body");
        if (elements.length > 0) {
            document.getElementsByTagName("body")[0].style.backgroundColor = "black";
        }
        // code here
        // this.props.setAdmin(true);
    }

    public activate(section: Section) {
        this.setState({ section });
    }

    public outputSection() {
        const section = this.state.section;
        let output: React.ReactElement = <></>;
        switch (section) {
            case Section.Blogs:
                output = <ManageBlogs datahelper={dataHelper} />;
                break;
            case Section.Events:
                output = <ManageEvents datahelper={dataHelper} />;
                break;
            case Section.Schedule:
                output = <ManageSchedules datahelper={dataHelper} />;
                break;
            case Section.Course:
                output = <ManageCourses datahelper={dataHelper} />;
                break;
            default:
                break;
        }
        return output;
    }

    public toggleMenu() {
        let mobileMenuClosed = !this.state.mobileMenuClosed;
        this.setState({ mobileMenuClosed });
    }

    render() {
        return <div className={styles.container}>
            <>
                <div className={[styles.sideMenu, this.state.mobileMenuClosed ? styles.closedSideMenu : ""].join(' ')}>
                    <div className={styles.profileInfo}>
                        <img alt="profilepic" width="35" height="35" src={profilePic} />
                        <span className={styles.online}></span>
                        <div>
                            <h4>Paula Tordoor</h4>
                            <p>HAPPY DANCER</p>
                        </div>
                        <span onClick={() => this.toggleMenu()} className={styles.barsIcon}><FontAwesomeIcon icon={faBars as any} /></span>
                    </div>
                    <p className={styles.navigation} style={{ marginLeft: 25, marginTop: 50 }}>Navigation</p>
                    <ul className={styles.nav}>
                        <li className={styles.active}>
                            <Link to="/admin">
                                <span className={styles.dashboardIcon}><FontAwesomeIcon icon={faTachometerAlt as any} /></span>
                                Dashboard
                            </Link>
                        </li>
                        <li>
                            <Link to="/"><span className={styles.websiteIcon}><FontAwesomeIcon icon={faLaptop as any} /></span>
                                Site bekijken
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className={styles.contentBlock}>
                    <div className={styles.row}>
                        <div className={styles.colLg3}>
                            <div onClick={() => this.activate(Section.Blogs)} className={[styles.block, styles.clickableBlock, this.state.section === Section.Blogs ? styles.activeBlock : ""].join(' ')}>
                                <div className={styles.infoCircle}>
                                    <FontAwesomeIcon icon={faInfoCircle as any} />
                                </div>
                                <div>
                                    <h3>Nieuwsberichten</h3>
                                    <p>Klik hier om uw nieuwsberichten bij te werken</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.colLg3}>
                            <div onClick={() => this.activate(Section.Events)} className={[styles.block, styles.clickableBlock, this.state.section === Section.Events ? styles.activeBlock : ""].join(' ')}>
                                <div className={styles.textIcon}>
                                    <FontAwesomeIcon icon={faCalendar as any} />
                                </div>
                                <div>
                                    <h3>Kalender</h3>
                                    <p>Klik hier om uw evenementen bij te werken</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.colLg3}>
                            <div onClick={() => this.activate(Section.Course)} className={[styles.block, styles.clickableBlock, this.state.section === Section.Course ? styles.activeBlock : ""].join(' ')}>
                                <div className={styles.groupIcon}>
                                    <FontAwesomeIcon icon={faUserGroup as any} />
                                </div>
                                <div>
                                    <h3>Cursussen</h3>
                                    <p>Klik hier om uw cursussen bij te werken</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.colLg3}>
                            <div onClick={() => this.activate(Section.Schedule)} className={[styles.block, styles.clickableBlock, this.state.section === Section.Schedule ? styles.activeBlock : ""].join(' ')}>
                                <div className={styles.menuIcon}>
                                    <FontAwesomeIcon icon={faCalendarDays as any} />
                                </div>
                                <div>
                                    <h3>Uurrooster</h3>
                                    <p>Klik hier om uw uurrooster bij te werken</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.row}>
                        {this.outputSection()}
                    </div>
                </div>
            </>
        </div>
    }
}