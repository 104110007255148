import React from "react";
import dashboardstyles from "./../Dashboard.module.scss";
import styles from "./ManageSchedules.module.scss";
import axios from "axios";
import "react-toggle/style.css"; // for ES6 modules
// import Select from 'react-select';
// import 'react-dropdown/style.css';
import 'react-dropdown/style.css';
import { DataHelper } from "../../../data/DataHelper";
// import { confirmAlert } from 'react-confirm-alert'; // Import
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Day } from "../../schedule/Schedule";
import Swal from "sweetalert2";
import { Types } from 'mongoose';

export interface Info {
    key: string;
    value: string;
}

export interface IManageSchedulesProps {
    datahelper: DataHelper;
}

export interface IManageSchedulesState {
    days: Day[];
    activeDay: string;
    tableId: string;
    message: string;
    messageClass: string;
}

const objectId = () => (new Types.ObjectId());

export class ManageSchedules extends React.Component<IManageSchedulesProps, IManageSchedulesState> {

    constructor(props: any) {
        super(props);
        this.state = { tableId: "", message: "", messageClass: "", days: [], activeDay: "" };
    }

    public componentDidMount() {
        this.getData();
    }

    public getData = () => {
        axios.get('/schedules').then(res => {
            let days = res?.data?.[0]?.days;
            const tableId = res?.data?.[0]?._id;
            this.setState({ days, tableId });
        });
    }

    public changeInfo = (day: string, danceId: string | undefined, prop: string, value: string) => {
        let indexToDelete = -1;
        if (danceId) {
            let days = this.state.days;
            for (let i = 0; i < days.length; i++) {
                if (day === days[i]?.day) {
                    for (let j = 0; j < days[i]?.dances?.length; j++) {
                        if (danceId === days[i]?.dances?.[j]?._id) {
                            if (prop === "delete") {
                                indexToDelete = j;
                            } else {
                                days[i].dances[j][prop] = value;
                            }
                        }
                    }
                    if (indexToDelete > -1) {
                        days[i]?.dances?.splice(indexToDelete, 1);
                    }
                }
            }
            this.setState({ days });
        }
    }

    public openDay(day: string) {
        if (this.state.activeDay === day) {
            this.setState({ activeDay: "" });
        } else {
            this.setState({ activeDay: day });
        }
    }

    public saveChanges = async () => {
        let errs = 0;
        const days = this.state.days;
        for (let i = 0; i < days?.length; i++) {
            for (let j = 0; j < days[i]?.dances.length; j++) {
                if (!days?.[i]?.dances?.[j]?.title || !days?.[i]?.dances?.[j]?.startTime || !days?.[i]?.dances?.[j]?.endTime) {
                    errs++;
                }
            }
        }
        if (errs === 0) {
            let message = "";
            let messageClass = "";
            try {
                const result = await axios.put('/schedules/' + this.state.tableId, { days: this.state?.days });
                if (result.status === 200) {
                    message = "Uw cursus werd gewijzigd!";
                    messageClass = dashboardstyles.success;
                } else {
                    message = "Er ging iets mis.";
                    messageClass = dashboardstyles.error;
                }
            } catch (err) {
                message = "Er ging iets mis.";
                messageClass = dashboardstyles.error;
            }
            this.setState({ message, messageClass }, () => this.getData());
        } else {
            Swal.fire('Oeps!', 'Zorg dat elke cursus een titel, start- en eindtijd heeft.', 'warning');
        }
    }

    render() {
        const activeDayObject = this.state.days?.filter(d => d?.day === this.state.activeDay)?.[0];
        return <>
            <div className={dashboardstyles.colLg3}>
                <div className={dashboardstyles.block}>
                    <div className={styles.width100}>
                        <h4>Uurrooster beheren</h4>
                        <p>Klik een dag aan om de cursussen van deze dag te bewerken.</p>
                        <div>
                            {this.state.days?.map((day, i) => {
                                return (
                                    <div key={i} className={[dashboardstyles.bgDarkBlockNoPadding, dashboardstyles.clickableEntity].join(' ')}>
                                        <div onClick={() => this.openDay(day?.day)} className={dashboardstyles.colLg11}><p>{day?.day}</p></div>
                                    </div>
                                );
                            })}
                        </div>

                    </div>
                </div>
            </div>
            {this.state.activeDay &&
                <div className={dashboardstyles.colLg9}>
                    <div className={dashboardstyles.block}>
                        <div className={styles.width100}>

                            <>
                                <span className={dashboardstyles.backButton} onClick={() => this.setState({ activeDay: "" })}>
                                    <FontAwesomeIcon icon={faArrowLeft as any} /> Terug naar het overzicht
                                </span>
                                <form>
                                    <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                                        <label className={dashboardstyles.colLg2}>{this.state?.activeDay}</label>
                                    </div>
                                    {activeDayObject?.dances?.map((a, i) => (
                                        <div key={i} className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                                            <div className={dashboardstyles.colLg6}>
                                                <input value={a?.title} onChange={(e) => this.changeInfo(this.state.activeDay, a?._id, "title", e.target.value)} placeholder="Titel" type="text" className={dashboardstyles.formControl} />
                                            </div>
                                            <div className={dashboardstyles.colLg2}>
                                                <div style={{ marginLeft: 5, marginRight: 5 }}>
                                                    <input value={a?.startTime} onChange={(e) => this.changeInfo(this.state.activeDay, a?._id, "startTime", e.target.value)} placeholder="Starttijd" type="text" className={dashboardstyles.formControl} />
                                                </div>
                                            </div>
                                            <div className={dashboardstyles.colLg2}>
                                                <input value={a?.endTime} onChange={(e) => this.changeInfo(this.state.activeDay, a?._id, "endTime", e.target.value)} placeholder="Eindtijd" type="text" className={dashboardstyles.formControl} />
                                            </div>
                                            <div className={dashboardstyles.colLg2}>
                                                <div onClick={() => this.changeInfo(this.state.activeDay, a?._id, "delete", "")} className={dashboardstyles.textAlignRight + " " + styles.removeDance}><p>&#10005;</p></div>
                                            </div>
                                        </div>
                                    )
                                    )}
                                    <div className={styles.addDance} onClick={() => {
                                        const days = this.state.days;
                                        for (let i = 0; i < days.length; i++) {
                                            if (days[i].day === activeDayObject.day) {
                                                days[i].dances.push({ _id: objectId()?.toString(), title: "", startTime: "", endTime: "" })
                                                this.setState({ days });
                                            }
                                        }
                                    }}>+</div>

                                    {this.state.message !== "" && <p className={this.state.messageClass}>{this.state.message}</p>}
                                    <span onClick={() => this.saveChanges()} className={dashboardstyles.button}>Opslaan</span>
                                </form>
                            </>

                        </div>
                    </div>
                </div>
            }
        </>
    }
}