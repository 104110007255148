import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from './GoTop.module.scss';

const GoTop = (props) => {
    return (
      <>
        <div className={props.showGoTop} onClick={props.scrollUp}>
          <button className={styles.goTop}>
            <i className="goTop__text fas fa-chevron-up" />
            <FontAwesomeIcon icon={faChevronUp as any} />
          </button>
        </div>
      </>
    );
  };
  export default GoTop;