import { useEffect, useState } from 'react';
import styles from './Events.module.scss';
import Calendar from 'react-calendar';
import { Type } from '../../data/Interfaces';
import axios from "axios";
import GoTop from '../goTop/GoTop';

export interface EventProps {
    _id?: string;
    title: string;
    startingHour: string;
    endingHour: string;
    eventDate: string;
    location: string;
    type: string;
}

function Events() {
    let startEvents: EventProps[] = [];
    const [events, setEvents] = useState(startEvents);
    const [value, onChange] = useState(new Date());
    const imageUrlFeest = "https://images.unsplash.com/photo-1560987617-6de57e3d0574?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80";
    const imageUrlLes = "https://images.unsplash.com/photo-1504609813442-a8924e83f76e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80";
    const imageUrlActiviteit = "https://images.unsplash.com/photo-1438557068880-c5f474830377?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1173&q=80";
    const imageUrlDansavond = "https://images.unsplash.com/photo-1470225620780-dba8ba36b745?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80";
    const imageUrlJeugd = "https://images.unsplash.com/photo-1535525153412-5a42439a210d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80";
    // const [scrollPosition, setSrollPosition] = useState(0);
    const [showGoTop, setshowGoTop] = useState(styles.goTopHidden);

    //SCROLL LISTENER
    useEffect(() => {
        window.addEventListener("scroll", handleVisibleButton);
    }, []);

    const handleVisibleButton = () => {
        const scrollPosition = window.scrollY;
        // setSrollPosition(scrollPosition);
    
        if (scrollPosition > 50) {
            return setshowGoTop(styles.goTop);
        } else if (scrollPosition < 50) {
            return setshowGoTop(styles.goTopHidden);
        }
    };
    
    const handleScrollUp = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    const tileClassName = (props) => {
        let firstDayOfCurrentMonth: Date = props.activeStartDate;
        let buttonDate: Date = props.date;

        let isCurrentMonth = buttonDate.getMonth() === firstDayOfCurrentMonth.getMonth();
        let isNextMonth = buttonDate.getMonth() !== firstDayOfCurrentMonth.getMonth() && buttonDate > firstDayOfCurrentMonth;

        // return props.view === 'month' && props.date.getDay() === 3 ? "wednesday" : "";
        return isNextMonth ? styles.hideMonth : (isCurrentMonth ? styles.currentMonth + " " + styles.month : styles.otherMonth + " " + styles.month);
    }

    const tileContent = (props) => {
        let firstDayOfCurrentMonth: Date = props.activeStartDate;
        let tileDate = props.date;
        let output = <div className={styles.usualDay}>
            <p className={styles.mobileWeekday}>{formatShortWeekday("nl-BE", tileDate)}</p>
            <p>{tileDate.getDate()}</p>
        </div>
        let eventsList: EventProps[] = [];
        if (events && events.length > 0) {
            events.forEach(event => {
                let year = parseInt(event.eventDate.split('/')[2]);
                let month = parseInt(event.eventDate.split('/')[1]) - 1;
                let day = parseInt(event.eventDate.split('/')[0]);

                let isPreviousMonth = props.date.getMonth() < firstDayOfCurrentMonth.getMonth();

                if (tileDate.getFullYear() === year && tileDate.getMonth() === month && tileDate.getDate() === day && !isPreviousMonth) {
                    eventsList.push(event);
                }
            });

            if (eventsList.length === 1) {
                let event = eventsList[0];
                // Same day, return the image + info
                let day = parseInt(event.eventDate.split('/')[0]);
                const imageUrl = returnPicForType(event.type);

                output =
                    <div className={styles.eventImage} style={{ backgroundImage: "url('" + imageUrl + "')" }}>
                        <div className={styles.fader}>
                            <span className={styles.dateFlag}>{day}</span>
                            <h4>{event.title}</h4>
                            <div className={styles.footerText}>
                                <p className={styles.subTitle}>{event.startingHour + " - " + event.endingHour} </p>
                                { event.location && event.location !== "Geen locatie" && <p className={styles.subTitle}>{event.location}</p>}
                            </div>
                        </div>
                    </div>
            } else if (eventsList.length > 1) {
                let day = parseInt(eventsList[0].eventDate.split('/')[0]);
                // Multiple on one tile
                return <div className={styles.pageOptionWrapper}>
                    <div className={styles.pageOption}>
                        <span className={styles.option} data-inf={eventsList[0].title + " @ " + eventsList[0].location + " | " + eventsList[0].startingHour + " - " + eventsList[0].endingHour}>
                            <img alt="event" src={returnPicForType(eventsList[0].type)} />
                        </span>
                        <span className={styles.option} data-inf={eventsList[1].title + " @ " + eventsList[1].location + " | " + eventsList[1].startingHour + " - " + eventsList[1].endingHour}>
                            <img alt="event" src={returnPicForType(eventsList[1].type)} />
                        </span>
                    </div>
                    <span className={styles.dateFlag}>{day}</span>

                </div>;
            }
        }
        return output;
    }

    const returnPicForType = (type: string) => {
        let imageUrl = "";

        switch (type) {
            case Type.Feest:
                imageUrl = imageUrlFeest;
                break;
            case Type.Activiteit:
                imageUrl = imageUrlActiviteit;
                break;
            case Type.Dansavond:
                imageUrl = imageUrlDansavond;
                break;
            case ("Training/Les"):
                imageUrl = imageUrlLes;
                break;
            case Type.Jeugd:
                imageUrl = imageUrlJeugd;
                break;
        }
        return imageUrl;
    }

    const formatShortWeekday = (locale, date) => {
        let dayName = "";
        switch (date.getDay()) {
            case 1:
                dayName = "Maandag";
                break;
            case 2:
                dayName = "Dinsdag";
                break;
            case 3:
                dayName = "Woensdag";
                break;
            case 4:
                dayName = "Donderdag";
                break;
            case 5:
                dayName = "Vrijdag";
                break;
            case 6:
                dayName = "Zaterdag";
                break;
            case 0:
                dayName = "Zondag";
                break;

        }
        return dayName;
    }

    useEffect(() => {
        axios.get('/events/').then(res => {
            setEvents(res.data);
        });
    }, []);

    return (
        <div className={styles.events + " events"}>
            <GoTop showGoTop={showGoTop} scrollUp={handleScrollUp}/>
            <div className={styles.container}>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <h1>Kalender</h1>

                    </div>
                </div>
                <div className={styles.row}>
                    <Calendar onChange={onChange} value={value} tileClassName={tileClassName} locale={"nl-BE"} minDetail={"month"} formatShortWeekday={formatShortWeekday} tileContent={tileContent} />
                </div>
            </div>
        </div>
    );


}

export default Events;