import React from "react";
import dashboardstyles from "./../Dashboard.module.scss";
import styles from "./ManageCourses.module.scss";
import axios from "axios";
import "react-toggle/style.css"; // for ES6 modules
import JoditEditor, { Jodit } from "jodit-react";
import 'react-dropdown/style.css';
import { DataHelper } from "../../../data/DataHelper";
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faLink } from "@fortawesome/free-solid-svg-icons";
import { CourseProps } from "../../courses/Courses";

const uploadImage = async (files) => {
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("upload_preset", "ypxpw4kf");
    let test = await fetch(
        "  https://api.cloudinary.com/v1_1/daanp/image/upload/",
        {
            method: "post",
            body: formData,
        }
    );
    let resp = await test.json();
    return resp?.url;
}

const editor = React.createRef<Jodit>();
const editor2 = React.createRef<Jodit>();
const config = {
    readonly: false,
    placeholder: 'Typ uw cursusbeschrijving hier'
};
const config2 = {
    readonly: false,
    placeholder: 'Typ uw prijsbeschrijving hier'
};


enum Section {
    NewBlogPost,
    ManageBlogPosts,
    None
}

export interface Info {
    key: string;
    value: string;
}

export interface IManageCoursesProps {
    datahelper: DataHelper;
}

export interface IManageCoursesState {
    section: Section;
    courses: CourseProps[];
    newCourse: CourseProps;
    tableId: string;
    message: string;
    messageClass: string;
}

export class ManageCourses extends React.Component<IManageCoursesProps, IManageCoursesState> {

    constructor(props: any) {
        super(props);
        this.state = { section: Section.None, courses: [], newCourse: { _id: "", title: "", description: "", pricing: "", imgUrl: "https://res.cloudinary.com/daanp/image/upload/v1662571462/default_iugs28.png" }, tableId: "", message: "", messageClass: "" };
    }

    public componentDidMount() {
        this.getData();
    }

    public getData = () => {
        axios.get('/courses').then(res => {
            let newCourse = { ...this.state.newCourse };

            const courses = res.data.sort((objA, objB) => (new Date(objB.createdAt)).getTime() - (new Date(objA.createdAt)).getTime());
            this.setState({ courses, newCourse });
        });
    }

    public activate = (section: Section) => {
        let newCourse = { _id: "", title: "", description: "", imgUrl: "", pricing: "" };
        this.setState({ section, message: "", messageClass: "", newCourse, tableId: "" });
    }

    public changeInfo = (attribute: string, value: string) => {
        let newCourse = { ...this.state.newCourse };
        newCourse[attribute] = value;
        this.setState({ newCourse });
    }

    public saveChanges = (_id: string) => {
        if (this.state.section === Section.ManageBlogPosts) {
            let tableId = this.state.tableId;
            if (this.state.newCourse.description &&
                this.state.newCourse.imgUrl &&
                this.state.newCourse.pricing &&
                this.state.newCourse.title) {
                axios.put('/courses/' + tableId, this.state.newCourse).then(res => {
                    let message = "";
                    let messageClass = "";
                    if (res.status === 200) {
                        message = "Uw cursus werd gewijzigd!";
                        messageClass = dashboardstyles.success;
                    } else {
                        message = "Er ging iets mis.";
                        messageClass = dashboardstyles.error;
                    }
                    this.setState({ message, messageClass }, () => this.getData());
                });
            } else {
                Swal.fire('Oeps!', 'Eén of meerdere verplichte velden werden niet ingevuld', 'warning');
            }
        } else if (this.state.section === Section.NewBlogPost) {
            if (this.state.newCourse.description &&
                this.state.newCourse.imgUrl &&
                this.state.newCourse.pricing &&
                this.state.newCourse.title) {
                axios.post('/courses/', this.state.newCourse).then(res => {
                    let message = "";
                    let messageClass = "";
                    if (res.status === 200) {
                        message = "Uw aanpassingen werden opgeslagen!";
                        messageClass = dashboardstyles.success;

                    } else {
                        message = "Er ging iets mis.";
                        messageClass = dashboardstyles.error;
                    }
                    this.setState({ message, messageClass }, () => this.getData());
                });
            } else {
                Swal.fire('Oeps!', 'Eén of meerdere verplichte velden werden niet ingevuld', 'warning');
            }
        }
    }

    public openBlogForEdit = (course: CourseProps) => {
        if (course._id) {
            this.setState({ tableId: course._id, newCourse: course });
        }
    }

    public removeBlog = (_id: string) => {
        Swal.fire({
            title: 'Verwijderen bevestigen',
            text: 'Weet u zeker dat u deze cursus wil verwijderen?',
            icon: 'error',
            confirmButtonText: 'OK'
        }).then(confirm => {
            if (confirm.isConfirmed) {
                let message = "";
                let messageClass = "";
                let newCourse = { _id: "", title: "", description: "", imgUrl: "", pricing: "" };
                if (_id && _id !== "") {
                    axios.delete('/courses/' + _id).then(res => {
                        if (res.status === 200) {
                            message = "Uw nieuwsbericht werd Verwijderd!";
                            messageClass = dashboardstyles.success;
                        } else {
                            message = "Er ging iets mis.";
                            messageClass = dashboardstyles.error;
                        }
                        this.setState({ tableId: "", section: Section.ManageBlogPosts, message, messageClass, newCourse }, () => this.getData());
                    });
                }
            }
        });
    }

    render() {
        return <>
            <div className={dashboardstyles.colLg3}>
                <div className={dashboardstyles.block}>
                    <div className={styles.width100}>
                        <h3>Maak een keuze</h3>
                        <p>Klik op één van onderstaande knoppen om een keuze te maken</p>
                        <div className={styles.selectInfo}>
                            <div onClick={() => this.activate(Section.NewBlogPost)} className={[dashboardstyles.bgDarkBlock, styles.selectItem, this.state.section === Section.NewBlogPost ? styles.selectedSection : ""].join(' ')}>
                                <h4>Nieuwe cursus</h4>
                                <p>Klik hier om een nieuwe cursus aan te maken</p>
                            </div>
                            <div onClick={() => this.activate(Section.ManageBlogPosts)} className={[dashboardstyles.bgDarkBlock, styles.selectItem, this.state.section === Section.ManageBlogPosts ? styles.selectedSection : ""].join(' ')}>
                                <h4>Cursussen beheren</h4>
                                <p>Klik hier om een bestaande cursus te wijzigen of verwijderen</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={dashboardstyles.colLg9}>
                {this.state.section === Section.NewBlogPost &&
                    <div className={dashboardstyles.block}>
                        <div className={styles.width100}>
                            <h4>Nieuwe cursus maken</h4>
                            <p>Vergeet niet om te klikken op "opslaan" wanneer je klaar bent!</p>
                            <form>
                                <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                                    <label className={dashboardstyles.colLg2}>Titel</label>
                                    <div className={dashboardstyles.colLg10}>
                                        <input value={this.state.newCourse?.title} onChange={(e) => this.changeInfo("title", e.target.value)} placeholder="Titel" type="text" className={dashboardstyles.formControl} />
                                    </div>
                                </div>
                                <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                                    <label className={dashboardstyles.colLg2}>Beschrijving</label>
                                    <div className={dashboardstyles.colLg10}>
                                        <div className={styles.joditEditor}>
                                            <JoditEditor
                                                ref={editor}
                                                value={this.state.newCourse?.description}
                                                config={config}
                                                onBlur={(e) => this.changeInfo("description", e)} // preferred to use only this option to update the content for performance reasons
                                                onChange={(e) => this.changeInfo("description", e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                                    <label className={dashboardstyles.colLg2}>Afbeelding</label>
                                    <div className={dashboardstyles.colLg10}>
                                        {!this.state.newCourse.imgUrl && <label htmlFor="files" className={dashboardstyles.button}>Afbeelding kiezen</label>}

                                        <input id="files" style={{ display: "none" }} type="file"
                                            onChange={async (e) => {
                                                let newLocation = await uploadImage(e.target.files);
                                                this.changeInfo("imgUrl", newLocation);
                                            }}
                                        />
                                        {this.state.newCourse.imgUrl &&
                                            <img alt="course" src={this.state.newCourse.imgUrl} style={{ maxWidth: 200 }} />}
                                    </div>
                                </div>

                                <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                                    <label className={dashboardstyles.colLg2}>Prijs</label>
                                    <div className={dashboardstyles.colLg10}>
                                        <div className={styles.joditEditor}>
                                            <JoditEditor
                                                ref={editor2}
                                                value={this.state.newCourse?.pricing}
                                                config={config2}
                                                onBlur={(e) => this.changeInfo("pricing", e)} // preferred to use only this option to update the content for performance reasons
                                                onChange={(e) => this.changeInfo("pricing", e)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {this.state.message !== "" && <p className={this.state.messageClass}>{this.state.message}</p>}
                                <span onClick={() => this.saveChanges("")} className={dashboardstyles.button}>Opslaan</span>
                            </form>
                        </div>
                    </div>
                }
                {this.state.section === Section.ManageBlogPosts &&
                    <div className={dashboardstyles.block}>
                        <div className={styles.width100}>
                            <h4>Cursussen beheren</h4>
                            <p>Klik een cursus aan om deze te bewerken. Klik op het kruisje rechts om een cursus te verwijderen.</p>
                            {!this.state.tableId ?
                                <div>
                                    {this.state.courses.map((course, i) => {
                                        return (
                                            <div key={i} className={[dashboardstyles.bgDarkBlockNoPadding, dashboardstyles.clickableEntity].join(' ')}>
                                                <div onClick={() => this.openBlogForEdit(course)} className={dashboardstyles.colLg10}><p>{course.title}</p></div>
                                                <div onClick={() => {navigator.clipboard.writeText("https://www.happydancer.be/cursussen?course=" + course.title)}} className={dashboardstyles.colLg1 + " " + dashboardstyles.textAlignRight + " " + styles.copyLink}>
                                                    <div> <FontAwesomeIcon icon={faLink as any} /> </div>
                                                </div>
                                                <div onClick={() => this.removeBlog(course._id ?? "")} className={dashboardstyles.colLg1 + " " + dashboardstyles.textAlignRight + " " + dashboardstyles.cross}><p>&#10005;</p></div>
                                            </div>
                                        );
                                    })}
                                    {this.state.courses.length === 0 &&
                                        <div key={11} className={dashboardstyles.bgDarkBlock}>
                                            <div className={dashboardstyles.colLg11}><p style={{ color: "white" }}>U heeft nog geen cursussen. Klik linksboven op de knop 'Nieuwe cursus' om een nieuw bericht aan te maken!</p></div>
                                        </div>
                                    }
                                </div> :
                                <>
                                    <span className={dashboardstyles.backButton} onClick={() => this.setState({ tableId: "" })}>
                                        <FontAwesomeIcon icon={faArrowLeft as any} /> Terug naar het overzicht
                                    </span>
                                    <form>
                                        <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                                            <label className={dashboardstyles.colLg2}>Titel</label>
                                            <div className={dashboardstyles.colLg10}>
                                                <input value={this.state.newCourse?.title} onChange={(e) => this.changeInfo("title", e.target.value)} placeholder="Titel" type="text" className={dashboardstyles.formControl} />
                                            </div>
                                        </div>
                                        <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                                            <label className={dashboardstyles.colLg2}>Beschrijving</label>
                                            <div className={dashboardstyles.colLg10}>
                                                <div className={styles.joditEditor}>
                                                    <JoditEditor
                                                        ref={editor}
                                                        value={this.state.newCourse?.description}
                                                        config={config}
                                                        onBlur={(e) => this.changeInfo("description", e)} // preferred to use only this option to update the content for performance reasons
                                                        onChange={(e) => this.changeInfo("description", e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                                            <label className={dashboardstyles.colLg2}>Afbeelding</label>
                                            <div className={dashboardstyles.colLg10}>
                                                {!this.state.newCourse.imgUrl && <label htmlFor="files" className={dashboardstyles.button}>Afbeelding kiezen</label>}

                                                <input id="files" style={{ display: "none" }} type="file"
                                                    onChange={async (e) => {
                                                        let newLocation = await uploadImage(e.target.files);
                                                        this.changeInfo("imgUrl", newLocation);
                                                    }}
                                                />
                                                {this.state.newCourse.imgUrl &&
                                                    <img alt="course" src={this.state.newCourse.imgUrl} style={{ maxWidth: 200 }} />}
                                            </div>
                                        </div>

                                        <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                                            <label className={dashboardstyles.colLg2}>Prijs</label>
                                            <div className={dashboardstyles.colLg10}>
                                                <div className={styles.joditEditor}>
                                                    <JoditEditor
                                                        ref={editor2}
                                                        value={this.state.newCourse?.pricing}
                                                        config={config2}
                                                        onBlur={(e) => this.changeInfo("pricing", e)} // preferred to use only this option to update the content for performance reasons
                                                        onChange={(e) => this.changeInfo("pricing", e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {this.state.message !== "" && <p className={this.state.messageClass}>{this.state.message}</p>}
                                        <span onClick={() => this.saveChanges("")} className={dashboardstyles.button}>Opslaan</span>
                                    </form>
                                </>

                            }

                        </div>
                    </div>
                }
            </div>
        </>
    }
}