import { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import Fade from 'react-reveal/Fade';
import VisibilitySensor from 'react-visibility-sensor';
import GoTop from '../goTop/GoTop';
import styles from './AboutUs.module.scss';

function AboutUs() {
    const year = (new Date().getFullYear());
    const [assets] = useState([{ "Label": "Toffe cursussen", "value": 10 }, { "Label": "Leden", "value": 213 }, { "Label": "Dansstijlen", "value": 23 }, { "Label": "Dansleraars", "value": 14 }, { "Label": "Jaren ervaring", "value": (year - 1996) }]);
    const [showGoTop, setshowGoTop] = useState(styles.goTopHidden);
    // const [scrollPosition, setSrollPosition] = useState(0);

    useEffect(() => {
        window.addEventListener("scroll", handleVisibleButton);
    }, []);

    const handleVisibleButton = () => {
        const scrollPosition = window.scrollY;
        // setSrollPosition(scrollPosition);

        if (scrollPosition > 50) {
            return setshowGoTop(styles.goTop);
        } else if (scrollPosition < 50) {
            return setshowGoTop(styles.goTopHidden);
        }
    };

    const handleScrollUp = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    return (
        <div className={styles.aboutUs}>
            <GoTop showGoTop={showGoTop} scrollUp={handleScrollUp}/>
            <section className={styles.banner}>
                <section className={styles.introSection}>
                    <div className={styles.introtext}>
                        <div>
                            <h1>Happy Dancer </h1>
                            <p style={{ marginTop: 62 }}><b>Dansclub Happy Dancer Ham</b> is een dansclub waar gezelligheid en vriendschap centraal staat. Jong en oud kunnen er in een ontspannen sfeer een ruime keuze aan danslessen volgen, aangeleerd door onze gedreven dansleraars.</p>
                        </div>
                    </div>
                </section>
            </section>
            <section className={styles.infoSection}>
                <div className={styles.info}>
                    <div className={styles.row}>
                        <div className={styles.collg4}>
                            <div style={{ backgroundImage: "url('https://res.cloudinary.com/daanp/image/upload/v1662403953/4cursussen_lqytyg.png')" }} className={styles.imageColumn}>
                                <div className={styles.overlay}>
                                    <p>Wist je dat we bovenop ons vast aanbod van danscursussen elk dansjaar 5 snelcursussen aanbieden van 7 lessen? Dit zijn salsa, popcorn, bachata, café boogie en disco swing.</p>
                                </div>
                                <Fade duration={1000} bottom>
                                    <div className={styles.groupcard}>
                                        <h4>WIST JE DAT?</h4>
                                    </div>
                                </Fade>

                            </div>

                        </div>
                        <div className={styles.break}></div>
                        <div className={styles.collg4}>
                            <div style={{ backgroundImage: "url('https://res.cloudinary.com/daanp/image/upload/v1662404011/happy_c3hq2e.png')" }} className={styles.imageColumn}>
                                <div className={styles.overlay}>
                                    <p>Wist je dat het wetenschappelijk bewezen is dat dansen je gelukkiger maakt? Tijdens het dansen maak je de stof endorfine aan in de hersenen. Deze zorgt ervoor dat je je gelukkig voelt. Iemand die minstens één keer per week danst, heeft een grotere kans op een gelukkig en evenwichtig leven.</p>
                                </div>
                                <Fade duration={1000} bottom>
                                    <div className={styles.groupcard}>
                                        <h4>WIST JE DAT?</h4>
                                    </div>

                                </Fade>

                            </div>

                        </div>
                        <div className={styles.break}></div>
                        <div className={styles.collg4}>
                            <div style={{ backgroundImage: "url('https://res.cloudinary.com/daanp/image/upload/v1662405121/dansen_ojhmlr.png')" }} className={styles.imageColumn}>
                                <div className={styles.overlay}>
                                    <p>Wist je dat we elke vrijdag een dansavond houden? Vanaf 20.00u krijgt iedereen de kans om de aangeleerde dansen in te oefenen, of gewoon gezellig een drankje te doen of een babbeltje te slaan.</p>
                                </div>
                                <Fade duration={1000} bottom>
                                    <div className={styles.groupcard}>
                                        <h4>WIST JE DAT?</h4>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.colLg12}>

                            <h2>Over ons</h2>

                        </div>
                    </div>
                </div>
            </section>

            <section className={styles.assetSection}>
                <div className={styles.assets}>
                    <Fade duration={500} distance={"65px"} top>
                        {assets.map((asset, i) => (
                            <div key={asset?.value + i.toString()} className={styles.asset}>
                                <span className={styles.number}>
                                    {/* <CountUp end={asset.value} duration={1.2} />  */}
                                    <CountUp end={asset.value}>
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>
                                </span>
                                <span className={styles.assetItem}>{asset.Label}</span>
                            </div>
                        ))}
                    </Fade>
                </div>
            </section>
            <section className={styles.infoSection}>
                <div className={styles.info}>
                    <div className={styles.row}>
                        <div className={styles.colLg12 + " " + styles.actualInfo}>
                            <p>Dansclub Happy Dancer werd op 3 juli 1996 opgericht door een vriendengroep met een passie voor dans. Er werd gestart met jeugdcursussen en koppeldans. Daar bleef het echter niet bij want ook line dance en country dance werden even later in het danspakket opgenomen. Naast koppeldans waar disco, chachacha, jive, quickstep, Engelse wals, rumba, mambo en nog zoveel meer dansen worden aangeleerd, bieden wij ook kortere cursussen zoals café boogie, bachata, salsa, popcorn en disco swing aan. Dus voor elk wat wils!</p>
                            <p>De aanvang van het dansseizoen begint de eerste zaterdag van september met een knallende opendeur in De Zille in Ham. Daarna gaan de wekelijkse danslessen van start en dit tot en met april. Op vrijdagavond is er onze dansavond waar er gezellig kan geoefend worden tussen pot en pint en natuurlijk mogen ook onze jaarlijkse feestjes niet ontbreken! De lessen en dansavonden gaan allemaal door in Het Kristoffelheem in Ham.</p>
                            <p>Wil je meer weten over Dansclub Happy Dancer? Neem dan verder een kijkje op onze website of aarzel niet om ons te contacteren per mail, telefoon, Instagram of Facebook.</p>
                            <p>Jij bent áltijd welkom en hopelijk tot binnenkort op de dansvloer bij Dansclub Happy Dancer!</p>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default AboutUs;