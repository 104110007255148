export interface IEvent {
    datum:string;
    startUur: string;
    eindUur: string;
    titel: string;
    locatie: string;
    type: string;
}

export enum Type {
    Les = "Les/Training",
    Feest = "Feest",
    Activiteit = "Activiteit",
    Dansavond = "Dansavond",
    Jeugd = "Jeugd"
}