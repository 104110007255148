import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Blog from "./blog/Blog";
import axios from 'axios';
import styles from "./Blogs.module.scss";
import { DataHelper } from "../../data/DataHelper";
import GoTop from "../goTop/GoTop";

export interface BlogProps {
    id: number;
    _id: string;
    title: string;
    content: string;
    imgUrl: string;
    date?: string;
    updatedAt?: string;
    createdAt?: string;
    eventDate?: string;
    location?: string;
    startingHour?: string;
}

interface BlogsProps {
    datahelper: DataHelper;
}

const Blogs = (props: BlogsProps) => {

    const [blogs, setBlogs] = useState<BlogProps[]>([]);
    const [blog, setBlog] = useState<BlogProps>();
    const [id] = useState(window.location?.pathname?.split('/')?.[2]);
    const [showGoTop, setshowGoTop] = useState(styles.goTopHidden);
    // const [scrollPosition, setSrollPosition] = useState(0);

    const handleVisibleButton = () => {
        const scrollPosition = window.scrollY;
        // setSrollPosition(scrollPosition);

        if (scrollPosition > 50) {
            return setshowGoTop(styles.goTop);
        } else if (scrollPosition < 50) {
            return setshowGoTop(styles.goTopHidden);
        }
    };

    const handleScrollUp = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    // const id = useParams();
    useEffect(() => {
        window.addEventListener("scroll", handleVisibleButton);
        
        axios.get('/blogs/').then(res => {
            let blogsTemp = props.datahelper.sortByDateStringDescending(res.data);
            let blogs = blogsTemp.length > 10 ? blogsTemp.slice(-10) : blogsTemp;
            setBlogs(blogs);
            let blogPost;
            if (id) {
                blogPost = blogs.filter(bp => bp.id.toString() === id.toString())?.[0];
            } else {
                blogPost = blogs[0];
            }
            setBlog(blogPost);
        });
    }, [id, props.datahelper]);

    const outputSubTitle = (blogpost: BlogProps) => {
        const items: string[] = [];
        if (blogpost.location) {
            items.push(blogpost.location);
        }
        if (blogpost.eventDate) {
            items.push(blogpost.eventDate);
        }
        if (blogpost.startingHour) {
            items.push(blogpost.startingHour);
        }

        return items.length > 0 ? <span className={styles.eventFlag}>{items.join(" • ")}</span> : <></>;
    }

    return (
        <div className={styles.blogs}>
            <GoTop showGoTop={showGoTop} scrollUp={handleScrollUp} />
            <div className={"container"}>
                <div className={"row"}>
                    <h1 style={{ marginBottom: "2em" }}>Nieuws</h1>
                    <div className={"col-lg-8"}>
                        {blog && <Blog blog={blog} />}
                    </div>
                    <div className={"col-lg-4"}>
                        <section className={styles.blogPostOverview}>
                            {props.datahelper.sortByDateStringAscending(blogs).map((blogMap, i) => {
                                if (blogMap._id !== blog?._id) {
                                    return (
                                        <Link to={`/nieuws/${blogMap.id}`} key={blogMap.id + "key" + i}>
                                            <div className={styles.overview} key={blogMap.id + i}>
                                                {outputSubTitle(blogMap)}
                                                <img alt="news img" src={blogMap.imgUrl} />
                                                <div className={styles.overviewBody}>
                                                    <h3>{blogMap.title}</h3>
                                                    {blogMap.date && <span className={styles.date}>{blogMap.date}</span>}
                                                    <button>MEER LEZEN</button>
                                                </div>
                                            </div>
                                        </Link>
                                    );
                                } else return <></>;
                            })}
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Blogs;