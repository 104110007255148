import JoditEditor, { Jodit } from "jodit-react";
import { FunctionComponent } from "react";
import DatePicker from "react-date-picker";
import { BlogProps } from "../../blogs/Blogs";
import dashboardstyles from "./../Dashboard.module.scss";
import styles from "./ManageBlogs.module.scss";
import Toggle from 'react-toggle';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

interface ManageBlogProps {
    blogPost: BlogProps;
    changeInfo: (attribute: string, value: string) => void;
    editor: React.Ref<Jodit>;
    config: any;
    uploadImage: (files: any) => Promise<any>;
    toggleLocationAttached: () => void;
    locationAttached: boolean;
    chosenDate: Date;
    chosenHour: string;
    setChosenHour: (chosenHour: string) => void;
    chosenMinute: string;
    setChosenMinute: (chosenMinute: string) => void;
    updateDate: (date: Date) => void;
    message: string;
    messageClass: string;
    saveChanges: (_id: string) => void;
}

const ManageBlog: FunctionComponent<ManageBlogProps> = (props) => {
    return (
        <form>
            <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                <label className={dashboardstyles.colLg2}>Titel</label>
                <div className={dashboardstyles.colLg10}>
                    <input value={props.blogPost?.title} onChange={(e) => props.changeInfo("title", e.target.value)} placeholder="Titel" type="text" className={dashboardstyles.formControl} />
                </div>
            </div>
            <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                <label className={dashboardstyles.colLg2}>Inhoud</label>
                <div className={dashboardstyles.colLg10}>
                    <div className={styles.joditEditor}>
                        <JoditEditor
                            ref={props.editor}
                            value={props.blogPost?.content}
                            config={props.config}
                            onBlur={(e) => props.changeInfo("content", e)} // preferred to use only this option to update the content for performance reasons
                            onChange={(e) => props.changeInfo("content", e)}
                        />
                    </div>
                </div>
            </div>
            <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                <label className={dashboardstyles.colLg2}>Afbeelding</label>
                <div className={dashboardstyles.colLg10}>
                    {(!props.blogPost.imgUrl || props.blogPost.imgUrl === "https://res.cloudinary.com/daanp/image/upload/v1662571462/default_iugs28.png") && <label htmlFor="files" className={dashboardstyles.button}>Afbeelding kiezen</label>}

                    <input id="files" style={{ display: "none" }} type="file"
                        onChange={async (e) => {
                            let newLocation = await props.uploadImage(e.target.files);
                            props.changeInfo("imgUrl", newLocation);
                        }}
                    />
                    {props.blogPost.imgUrl &&
                        <label className={dashboardstyles.uploadImage} htmlFor={"files"}>
                            <div className={dashboardstyles.imageWrapper}>
                                <img src={props.blogPost.imgUrl} alt="blog" style={{ maxWidth: 200 }} />
                                <div>Nieuwe afbeelding kiezen</div>
                            </div>
                        </label>
                    }
                </div>
            </div>


            <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                <label className={dashboardstyles.colLg2}>Locatie koppelen</label>
                <Toggle
                    defaultChecked={props.locationAttached}
                    icons={false}
                    onChange={props.toggleLocationAttached} />
            </div>
            <label>

            </label>
            <div className={dashboardstyles.transition + " " + (props.locationAttached ? "" : dashboardstyles.overflowHidden)} style={{ maxHeight: props.locationAttached ? 500 : 0 }}>
                <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                    <label className={dashboardstyles.colLg2}>Locatie</label>
                    <div className={dashboardstyles.colLg10}>
                        <Dropdown
                            className={[dashboardstyles.dropdown, dashboardstyles.minWidth176].join(' ')}
                            value={props.blogPost.location}
                            onChange={(e) => {
                                props.changeInfo("location", e && e.value ? e.value : "locatie")
                            }}
                            options={[
                                {
                                    label: "De Zille", value: "De Zille"
                                }, {
                                    label: "Kristoffelheem", value: "Kristoffelheem"
                                }
                            ]} />
                    </div>
                </div>
                <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                    <label className={dashboardstyles.colLg2}>Datum</label>
                    <div id="datePicker">
                        <DatePicker onChange={props.updateDate} locale={"nl-BE"} value={props.chosenDate} />
                    </div>
                </div>

                <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                    <label className={dashboardstyles.colLg2 + " " + dashboardstyles.startuurMobileLabel}>Startuur</label>
                    {/* <Dropdown
                        className={dashboardstyles.timerDropdown}
                        value={props.chosenHour}
                        onChange={(e) => {
                            props.setChosenHour(e.value);
                        }}
                        options={props.hourOptions}
                    /> */}
                    <div className={dashboardstyles.displayFlex+ " " + dashboardstyles.colLg10}>
                    <div className={dashboardstyles.colLg2 + " " + dashboardstyles.mr10}>
                        <input type="number" step="1" min="00" max="23" value={props.chosenHour} className={dashboardstyles.formControl} onChange={(e) => {
                            props.setChosenHour(e.target.value);
                        }} />
                    </div>
                    <div className={dashboardstyles.colLg2}>
                        <input type="number" step="1" min="00" max="59" value={props.chosenMinute} className={dashboardstyles.formControl} onChange={(e) => {
                            props.setChosenMinute(e.target.value);
                        }} />
                    </div>
                    </div>

                </div>
            </div>

            {props.message !== "" && <p className={props.messageClass}>{props.message}</p>}
            <span onClick={() => props.saveChanges(props.blogPost._id)} className={dashboardstyles.button}>Opslaan</span>
        </form>
    );
}

export default ManageBlog;