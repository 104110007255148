import { FunctionComponent } from "react";
import DatePicker from "react-date-picker";
import dashboardstyles from "./../Dashboard.module.scss";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { EventProps } from "../../events/Events";

interface ManageEventProps {
    eventPost: EventProps;
    changeInfo: (attribute: string, value: string) => void;
    chosenDate: Date;
    chosenHour: any;
    setChosenHour: (chosenHour: any) => void;
    chosenMinute: any;
    setChosenMinute: (chosenMinute: any) => void;
    chosenEndHour: any;
    setChosenEndHour: (chosenHour: any) => void;
    chosenEndMinute: any;
    setChosenEndMinute: (chosenMinute: any) => void;
    updateDate: (date: Date) => void;
    message: string;
    messageClass: string;
    saveChanges: (_id: string) => void;
    eventTypes: string[];
    chosenEventType: any;
    setChosenEventType: (type: any) => void;
}

const ManageEvent: FunctionComponent<ManageEventProps> = (props) => {
    return (
        <form>
            <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                <label className={dashboardstyles.colLg2}>Titel</label>
                <div className={dashboardstyles.colLg10}>
                    <input value={props.eventPost?.title} onChange={(e) => props.changeInfo("title", e.target.value)} placeholder="Titel" type="text" className={dashboardstyles.formControl} />
                </div>
            </div>
            <div>
                <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                    <label className={dashboardstyles.colLg2}>Locatie</label>
                    <div className={dashboardstyles.colLg10}>
                        <Dropdown
                            className={[dashboardstyles.dropdown, dashboardstyles.minWidth176].join(' ')}
                            value={props.eventPost.location}
                            onChange={(e) => {
                                props.changeInfo("location", e ? e.value : "locatie")
                            }}
                            options={[
                                "Geen locatie",
                                "De Zille",
                                "Kristoffelheem"
                            ]}
                        />
                    </div>
                </div>
                <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                    <label className={dashboardstyles.colLg2}>Datum</label>
                    <div id="datePicker">
                        <DatePicker onChange={props.updateDate} locale={"nl-BE"} value={props.chosenDate} />
                    </div>
                </div>

                <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                    <label className={dashboardstyles.colLg2}>Startuur</label>
                    <div className={dashboardstyles.colLg10} style={{ display: 'flex' }}>
                        <div className={dashboardstyles.colLg2 + " " + dashboardstyles.mr10}>
                            <input type="number" step="1" min="00" max="23" value={props.chosenHour} className={dashboardstyles.formControl} onChange={(e) => {
                                props.setChosenHour(e.target.value);
                            }} />
                        </div>
                        <div className={dashboardstyles.colLg2}>
                            <input type="number" step="1" min="00" max="59" value={props.chosenMinute} className={dashboardstyles.formControl} onChange={(e) => {
                                props.setChosenMinute(e.target.value);
                            }} />
                        </div>
                    </div>
                    {/* <Select
                        className={dashboardstyles.timerDropdown}
                        defaultValue={props.chosenHour}
                        onChange={(e) => {
                            props.setChosenHour(e.value);
                        }}
                        options={props.hourOptions}
                    />
                    <Select
                        className={dashboardstyles.timerDropdown}
                        defaultValue={props.chosenMinute}
                        onChange={(e) => {
                            props.setChosenMinute(e.value);
                        }}
                        options={props.minuteOptions}
                    /> */}

                </div>
                <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                    <label className={dashboardstyles.colLg2}>Einduur</label>
                    <div className={dashboardstyles.colLg10} style={{ display: 'flex' }}>
                        <div className={dashboardstyles.colLg2 + " " + dashboardstyles.mr10}>
                            <input type="number" step="1" min="00" max="23" value={props.chosenEndHour} className={dashboardstyles.formControl} onChange={(e) => {
                                props.setChosenEndHour(e.target.value);
                            }} />
                        </div>
                        <div className={dashboardstyles.colLg2}>
                            <input type="number" step="1" min="00" max="59" value={props.chosenEndMinute} className={dashboardstyles.formControl} onChange={(e) => {
                                props.setChosenEndMinute(e.target.value);
                            }} />
                        </div>
                    </div>
                    {/* <Select
                        className={dashboardstyles.timerDropdown}
                        defaultValue={props.chosenEndHour}
                        onChange={(e) => {
                            props.setChosenEndHour(e.value);
                        }}
                        options={props.hourOptions}
                    />
                    <Select
                        className={dashboardstyles.timerDropdown}
                        defaultValue={props.chosenEndMinute}
                        onChange={(e) => {
                            props.setChosenEndMinute(e.value);
                        }}
                        options={props.minuteOptions}
                    /> */}
                </div>
                <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                    <label className={dashboardstyles.colLg2}>Event type</label>
                    <Dropdown
                        className={[dashboardstyles.dropdown, dashboardstyles.minWidth176].join(' ')}
                        value={props.eventPost.type}
                        onChange={(e) => {
                            props.setChosenEventType(e.value);
                        }}
                        options={props.eventTypes}
                    />
                </div>
            </div>

            {props.message !== "" && <p className={props.messageClass}>{props.message}</p>}
            <span onClick={() => props.saveChanges(props.eventPost._id ?? "")} className={dashboardstyles.button}>Opslaan</span>
        </form>
    );
}

export default ManageEvent;