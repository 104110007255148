import { FunctionComponent } from "react";
import logo from './../../assets/images/logo.png';
import { NavLink } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import styles from "./Footer.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faLocationDot, faMobileScreenButton } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const instaIcon = faInstagram as IconProp;
const fbIcon = faFacebook as IconProp;
// const ytIcon = faYoutube as IconProp;

interface FooterProps {

}

const Footer: FunctionComponent<FooterProps> = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerBody}>
                <div className={"container"}>
                    <div className={"row"}>
                        <aside className={styles.colOne}>
                            <NavLink to="/">
                                <Fade duration={1500} bottom>
                                    <img src={logo} alt="logo" className={styles.logo} />
                                </Fade>
                            </NavLink>
                            <p>Leer dansen op een aangename en ontspannen manier in de gezelligste club van uren in de omtrek!</p>
                            <p>Dansclub Happy Dancer is lid van Danssport Vlaanderen.</p>
                            <section className={styles.sponsors}>
                                <a rel="noreferrer" target="_blank" href="https://www.danssportvlaanderen.be/">
                                    <img className={styles.logoDanssport} src="https://www.danssportvlaanderen.be/build/website/images/logo-red.png" alt="danssport vlaanderen" title="danssport vlaanderen logo" />
                                </a>
                            </section>

                        </aside>
                        <aside className={styles.colTwo}>
                            <h6>CONTACT & LOCATIE</h6>
                            <div className={styles.contactLocation}>
                                <div className={styles.iconHolder}>
                                    <FontAwesomeIcon icon={faLocationDot as any} />
                                </div>
                                <div>
                                    <p>Kristoffelheem</p>
                                    <p>Schoolstraat 10</p>
                                    <p>3945 Ham</p>
                                </div>
                            </div>
                            <div className={styles.contactLocation}>
                                <div className={styles.iconHolder}>
                                    <FontAwesomeIcon icon={faEnvelope as any} />
                                </div>
                                <div>
                                    <a href="mailto:info@happydancer.be">info@happydancer.be</a>
                                </div>
                            </div>
                            <div className={styles.contactLocation}>
                                <div className={styles.iconHolder}>
                                    <FontAwesomeIcon icon={faMobileScreenButton as any} />
                                </div>
                                <div>
                                    <a href="callto:0477 77 27 29"> 0477 77 27 29</a>
                                    <a href="callto:0479 83 72 50"> 0479 83 72 50 (jeugd)</a>
                                </div>
                            </div>
                        </aside>
                        <aside className={styles.colTwo}>
                            {/* <h6>Contact</h6> */}
                            {/* <div className={styles.contactLinks}>
                            <a href="callto:013 66 53 99"><FontAwesomeIcon icon={faPhone} /> 013 66 53 99</a>
                            <a href="callto:0477 77 27 29"><FontAwesomeIcon icon={faPhoneSquare} /> 0477 77 27 29</a>
                        </div> */}
                            <h6>STAY CONNECTED </h6>
                            <section className={styles.socials}>
                                <a href="https://instagram.com/happydancerham"><FontAwesomeIcon icon={instaIcon} /></a>
                                <a href="https://www.facebook.com/profile.php?id=100039036912035"><FontAwesomeIcon icon={fbIcon} /></a>
                                {/* <a href="https://www.youtube.com"><FontAwesomeIcon icon={ytIcon} /></a> */}
                            </section>
                        </aside>
                    </div>
                </div>
            </div>
            <section className={styles.footerFooter}>
                <div className="container">
                    <div className="row">
                        <p>Ⓒ Copyright Dansclub Happy Dancer {(new Date()).getFullYear()} - Website created by <a href="https://www.daanproost.be/" target="_blank" rel="noreferrer" style={{color:'white', cursor:'pointer'}}>Daan Proost</a></p>
                    </div>
                </div>
            </section>
        </footer>
    );
}

export default Footer;