import styles from './Schedule.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import Fade from 'react-reveal/Fade';
import { useEffect, useState } from 'react';
import axios from "axios";

export interface Day {
    _id?:string;
    day: string;
    dances: Dance[];
}

export interface Dance {
    _id?:string;
    title: string;
    startTime: string;
    endTime: string;
}

function Schedule() {

    const images = {
        0: "https://res.cloudinary.com/daanp/image/upload/v1662401437/pexels-photo-6788000_bx16ck.jpg",
        1: "https://res.cloudinary.com/daanp/image/upload/v1662391339/photo-1531747056595-07f6cbbe10ad_iocc1h.jpg",
        2: "https://res.cloudinary.com/daanp/image/upload/v1662391397/photo-1584772988869-dccc362700a6_jfbhcp_gvdohy.jpg",
        3: "https://res.cloudinary.com/daanp/image/upload/v1662391413/photo-1504609813442-a8924e83f76e_oo8uq0.jpg",
        4: "https://res.cloudinary.com/daanp/image/upload/v1662391435/photo-1595033950840-84340c803643_qdvxop.jpg"
    }

    const [days, setDays] = useState([] as Day[]);

    useEffect(() => {

     
        axios.get('/schedules').then(res => {
            if (res?.data?.[0]?.days?.length > 0) {
                setDays(res.data[0].days);
            }
        });
    }, []);


    return (
        <div className={styles.schedule}>
            <div className={styles.container}>
                <h1>Uurrooster</h1>
                <section className={styles.scheduleSection}>
                    <div className={styles.row}>
                        {days.map((n, i) => (
                            <div title={i.toString()} key={i.toString()} className={[styles.column, styles.colLg20].join(' ')}>
                                <Fade duration={1500} bottom distance={"100px"}>
                                    <div className={styles.innerBox}>
                                        <div className={styles.boxHeader}>
                                            {/* <h3>{n.day}</h3> */}
                                            <Fade duration={1500} top distance={"50px"}>
                                                <span className={styles.classCat}>{n.day}</span>
                                            </Fade>
                                        </div>

                                        <figure className={styles.imageBox}><img src={images[i]} alt="" />
                                            <div className={styles.overlay}></div>
                                        </figure>

                                        <div className={styles.boxContent}>
                                            <ul className={styles.classInfo}>
                                                {n.dances.map((dance, j) =>
                                                    <li key={i.toString() + j.toString() + dance.title.replace(/ /g, '').toString()}>
                                                        <div><FontAwesomeIcon icon={faClock as any} /></div>
                                                        <div className={styles.timeSlots}>
                                                            <span>{dance.startTime}</span>
                                                            <span>{dance.endTime}</span>
                                                        </div>
                                                        <div className={styles.title}><b>{dance.title.split(';').map((t, index) => <p key={index.toString() + dance.title} style={{ margin: 0 }}>{t}</p>)}</b></div>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        ))}
                    </div>
                </section>
            </div>
        </div >
    );
}

export default Schedule;