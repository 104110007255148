import { BlogProps } from "../components/blogs/Blogs";

export class DataHelper {

    public formatDate(date: Date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [day, month, year].join('/');
    }

    public dateStringToDate(splitter:string, dateString:string):Date {
        if (splitter && dateString) {
            let year = dateString.split(splitter)[2];
            let month = ("0" + (parseInt(dateString.split(splitter)[2]) - 1)).slice(-2);
            let day = ("0" + dateString.split(splitter)[2]).slice(-2);
            return new Date(month+"/" +day + "/" + year);
        } else {
            return new Date();
        }
    }

    public sortByDateStringDescending(items:BlogProps[]):BlogProps[] {
        return items.sort((a, b) => {
            return (new Date(a.createdAt ?? "").getTime() - new Date(b.createdAt ?? "").getTime());
        });
    }

    public sortByDateStringAscending(items:BlogProps[]):BlogProps[] {
        return items.sort((a, b) => {
            return (new Date(b.createdAt ?? "").getTime() - new Date(a.createdAt ?? "").getTime());
        });
    }

    public firstFreeId(array: number[], start: number) {
        array.every((a) => {
            if (start === a) {
                start = a + 1;
            }
            return true;
        });
        return start;
    }
}