import React from "react";
import dashboardstyles from "./../Dashboard.module.scss";
import styles from "./ManageBlogs.module.scss";
import axios from "axios";
import Toggle from 'react-toggle';
import "react-toggle/style.css"; // for ES6 modules
import { BlogProps } from "../../blogs/Blogs";
import JoditEditor, { Jodit } from "jodit-react";
import DatePicker from 'react-date-picker';
// import Select from 'react-select';
// import 'react-dropdown/style.css';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { DataHelper } from "../../../data/DataHelper";
import ManageBlog from "./ManageBlog";
// import { confirmAlert } from 'react-confirm-alert'; // Import
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const uploadImage = async (files) => {
    console.log(files);
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("upload_preset", "ypxpw4kf");
    try {
        let test = await fetch(
            "https://api.cloudinary.com/v1_1/daanp/image/upload/",
            {
                method: "post",
                body: formData,
            }
        );
        let resp = await test.json();
        return resp?.url;
    } catch (err) {
        console.error(err);
    }
}

const editor = React.createRef<Jodit>();
const config = {
    readonly: false,
    placeholder: 'Typ uw artikel hier',
    color: "red"
};


enum Section {
    NewBlogPost,
    ManageBlogPosts,
    None
}

export interface Info {
    key: string;
    value: string;
}

export interface IManageBlogsProps {
    datahelper: DataHelper;
}

export interface IManageBlogsState {
    section: Section;
    blogs: BlogProps[];
    newBlog: BlogProps;
    tableId: string;
    message: string;
    messageClass: string;
    locationAttached: boolean;
    minuteOptions: any[];
    hourOptions: any[];
    chosenDate: Date;
    chosenHour: any;
    chosenMinute: any;
}

export class ManageBlogs extends React.Component<IManageBlogsProps, IManageBlogsState> {

    constructor(props: any) {
        super(props);
        this.state = { chosenDate: new Date(), chosenHour: { label: '00', value: '00' }, chosenMinute: { label: '00', value: '00' }, section: Section.None, locationAttached: false, blogs: [], minuteOptions: [], hourOptions: [], newBlog: { id: -1, _id: "", title: "", content: "", imgUrl: "https://res.cloudinary.com/daanp/image/upload/v1662571462/default_iugs28.png", date: "" }, tableId: "", message: "", messageClass: "" };
    }

    public toggleLocationAttached = () => {
        this.setState({ locationAttached: !this.state.locationAttached });
    }

    public setChosenHour = (chosenHour) => {
        let newBlog = { ...this.state.newBlog };
        newBlog.startingHour = ("0" + chosenHour).slice(-2) + "." + ("0" + this.state.chosenMinute).slice(-2) + "u";
        this.setState({ chosenHour, newBlog });
    }

    public setChosenMinute = (chosenMinute) => {
        let newBlog = { ...this.state.newBlog };
        newBlog.startingHour = ("0" + this.state.chosenHour).slice(-2) + "." + ("0" + chosenMinute).slice(-2) + "u";
        this.setState({ chosenMinute, newBlog });
    }

    public updateDate = (e: Date) => {
        let dateString = this.props.datahelper.formatDate(e);
        let newBlog = { ...this.state.newBlog };
        newBlog.eventDate = dateString;
        this.setState({ chosenDate: e, newBlog });
    }

    public componentDidMount() {
        this.getData();
    }

    public getData = () => {
        axios.get('/blogs/').then(res => {
            let newBlog = { ...this.state.newBlog };
            let ids = res.data.map(d => d.id);
            ids = ids.sort((a, b) => { return a - b; });
            newBlog.id = this.props.datahelper.firstFreeId(ids, 1);
            let minuteOptions: any[] = [];
            let hourOptions: any[] = [];
            for (let i = 0; i <= 60; i++) {
                minuteOptions.push({ label: ("0" + i).slice(-2), value: ("0" + i).slice(-2) });
                if (i < 24) {
                    hourOptions.push({ label: ("0" + i).slice(-2), value: ("0" + i).slice(-2) });
                }
            }
            const blogs = res.data.sort((objA, objB) => (new Date(objB.createdAt)).getTime() - (new Date(objA.createdAt)).getTime());
            this.setState({ blogs, minuteOptions, hourOptions, newBlog });
        });
    }

    public activate = (section: Section) => {
        let currentBlog = { ...this.state.newBlog };
        let newBlog = { id: currentBlog.id, _id: "", title: "", content: "", imgUrl: "", date: "" };
        this.setState({ section, message: "", messageClass: "", newBlog, locationAttached: false, chosenDate: new Date(), chosenHour: { label: "00", value: "00" }, chosenMinute: { label: "00", value: "00" }, tableId: "" });
    }

    public changeInfo = (attribute: string, value: string) => {
        let newBlog = { ...this.state.newBlog };
        newBlog[attribute] = value;
        this.setState({ newBlog });
    }

    public saveChanges = (_id: string) => {
        if (this.state.section === Section.ManageBlogPosts) {
            let tableId = this.state.tableId;
            if (this.state.newBlog.content &&
                this.state.newBlog.imgUrl &&
                this.state.newBlog.title) {
                axios.put('/blogs/' + tableId, this.state.newBlog).then(res => {
                    let message = "";
                    let messageClass = "";
                    if (res.status === 200) {
                        message = "Uw nieuwsbericht werd gewijzigd!";
                        messageClass = dashboardstyles.success;
                    } else {
                        message = "Er ging iets mis.";
                        messageClass = dashboardstyles.error;
                    }
                    this.setState({ message, messageClass }, () => this.getData());
                });
            } else {
                Swal.fire('Oeps!', 'Eén of meerdere verplichte velden werden niet ingevuld', 'warning');
            }
        } else if (this.state.section === Section.NewBlogPost) {
            if (this.state.newBlog.content &&
                this.state.newBlog.imgUrl &&
                this.state.newBlog.title) {
                axios.post('/blogs/', this.state.newBlog).then(res => {
                    let message = "";
                    let messageClass = "";
                    if (res.status === 200) {
                        message = "Uw aanpassingen werden opgeslagen!";
                        messageClass = dashboardstyles.success;

                    } else {
                        message = "Er ging iets mis.";
                        messageClass = dashboardstyles.error;
                    }
                    this.setState({ message, messageClass }, () => this.getData());
                });
            } else {
                Swal.fire('Oeps!', 'Eén of meerdere verplichte velden werden niet ingevuld', 'warning');
            }
        }
    }

    public openBlogForEdit = (blogpost: BlogProps) => {
        let locationAttached = !(!blogpost.location || blogpost.location === "");
        let date = new Date();
        let dateString = "";
        let hour = "00";
        let minute = "00";
        if (blogpost.eventDate) {
            dateString = blogpost.eventDate.split("/")[1] + "/" + blogpost.eventDate.split("/")[0] + "/" + blogpost.eventDate.split("/")[2];
            date = new Date(dateString);
        }
        if (blogpost.startingHour) {
            hour = blogpost.startingHour.split(".")[0];
            minute = blogpost.startingHour.split(".")[1].slice(0, 2);
        }
        this.setState({ tableId: blogpost._id, newBlog: blogpost, locationAttached, chosenDate: date, chosenHour: hour, chosenMinute: minute });
    }

    public removeBlog = (_id: string) => {
        Swal.fire({
            title: 'Verwijderen bevestigen',
            text: 'Weet u zeker dat u dit nieuwsbericht wil verwijderen?',
            icon: 'error',
            confirmButtonText: 'OK'
        }).then(confirm => {
            if (confirm.isConfirmed) {
                let message = "";
                let messageClass = "";
                let newBlog = { id: -1, _id: "", title: "", content: "", imgUrl: "", date: "" };
                if (_id && _id !== "") {
                    axios.delete('/blogs/' + _id).then(res => {
                        if (res.status === 200) {
                            message = "Uw nieuwsbericht werd Verwijderd!";
                            messageClass = dashboardstyles.success;
                        } else {
                            message = "Er ging iets mis.";
                            messageClass = dashboardstyles.error;
                        }
                        this.setState({ tableId: "", section: Section.ManageBlogPosts, message, messageClass, newBlog, locationAttached: false, chosenDate: new Date(), chosenHour: { value: '00', label: '00' }, chosenMinute: { label: '00', value: '00' } }, () => this.getData());
                    });
                }
            }
        });
    }

    render() {
        return <>
            <div className={dashboardstyles.colLg3}>
                <div className={dashboardstyles.block}>
                    <div className={styles.width100}>
                        <h3>Maak een keuze</h3>
                        <p>Klik op één van onderstaande knoppen om een keuze te maken</p>
                        <div className={styles.selectInfo}>
                            <div onClick={() => this.activate(Section.NewBlogPost)} className={[dashboardstyles.bgDarkBlock, styles.selectItem, this.state.section === Section.NewBlogPost ? styles.selectedSection : ""].join(' ')}>
                                <h4>Nieuw nieuwsbericht</h4>
                                <p>Klik hier om een nieuwsbericht aan te maken</p>
                            </div>
                            <div onClick={() => this.activate(Section.ManageBlogPosts)} className={[dashboardstyles.bgDarkBlock, styles.selectItem, this.state.section === Section.ManageBlogPosts ? styles.selectedSection : ""].join(' ')}>
                                <h4>Nieuwsberichten beheren</h4>
                                <p>Klik hier om een bestaand nieuwsbericht te wijzigen of verwijderen</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={dashboardstyles.colLg9}>
                {this.state.section === Section.NewBlogPost &&
                    <div className={dashboardstyles.block}>
                        <div className={styles.width100}>
                            <h4>Nieuw nieuwsbericht maken</h4>
                            <p>Vergeet niet om te klikken op "opslaan" wanneer je klaar bent!</p>
                            <form>
                                <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                                    <label className={dashboardstyles.colLg2}>Titel</label>
                                    <div className={dashboardstyles.colLg10}>
                                        <input value={this.state.newBlog?.title} onChange={(e) => this.changeInfo("title", e.target.value)} placeholder="Titel" type="text" className={dashboardstyles.formControl} />
                                    </div>
                                </div>
                                <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                                    <label className={dashboardstyles.colLg2}>Inhoud</label>
                                    <div className={dashboardstyles.colLg10}>
                                        <div className={styles.joditEditor}>
                                            <JoditEditor
                                                ref={editor}
                                                value={this.state.newBlog?.content}
                                                config={config}
                                                onBlur={(e) => this.changeInfo("content", e)} // preferred to use only this option to update the content for performance reasons
                                                onChange={(e) => this.changeInfo("content", e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                                    <label className={dashboardstyles.colLg2}>Afbeelding</label>
                                    <div className={dashboardstyles.colLg10}>
                                        {!this.state.newBlog.imgUrl && <label htmlFor="files" className={dashboardstyles.button}>Afbeelding kiezen</label>}

                                        <input id="files" style={{ display: "none" }} type="file"
                                            onChange={async (e) => {
                                                let newLocation = await uploadImage(e.target.files);
                                                this.changeInfo("imgUrl", newLocation);
                                            }}
                                        />
                                        {this.state.newBlog.imgUrl &&
                                            <img alt="news" src={this.state.newBlog.imgUrl} style={{ maxWidth: 200 }} />}
                                    </div>
                                </div>


                                <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                                    <label className={dashboardstyles.colLg2}>Locatie koppelen</label>
                                    <Toggle
                                        defaultChecked={this.state.locationAttached}
                                        icons={false}
                                        onChange={() => this.setState({ locationAttached: !this.state.locationAttached })} />
                                </div>
                                <label>

                                </label>
                                <div className={dashboardstyles.transition + " " + (this.state.locationAttached ? "" : dashboardstyles.overflowHidden)} style={{ maxHeight: this.state.locationAttached ? 500 : 0 }}>
                                    <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                                        <label className={dashboardstyles.colLg2}>Locatie</label>
                                        <div className={dashboardstyles.colLg10}>
                                            <Dropdown
                                                className={[dashboardstyles.dropdown, dashboardstyles.minWidth176].join(' ')}
                                                value={this.state.newBlog?.location}
                                                onChange={(e) => {
                                                    this.changeInfo("location", e && e.value ? e.value : "locatie")
                                                }}
                                                options={["De Zille", "Kristoffelheem"]} />
                                        </div>

                                    </div>
                                    <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                                        <label className={dashboardstyles.colLg2}>Datum</label>
                                        <div id="datePicker">
                                            <DatePicker onChange={(e) => {
                                                let dateString = this.props.datahelper.formatDate(e);
                                                let newBlog = { ...this.state.newBlog };
                                                newBlog.eventDate = dateString;
                                                this.setState({ chosenDate: e, newBlog });
                                            }} locale={"nl-BE"} value={this.state.chosenDate} />
                                        </div>
                                    </div>

                                    <div className={[dashboardstyles.row, dashboardstyles.formGroup, dashboardstyles.mobileFlexRow].join(' ')}>
                                        <label className={dashboardstyles.colLg2}>Startuur</label>
                                        <div className={dashboardstyles.colLg1 + " " + dashboardstyles.mr10}>
                                            <input type="number" step="1" min="00" max="23" value={this.state.chosenHour} className={dashboardstyles.formControl} onChange={(e) => {
                                                let newBlog = { ...this.state.newBlog };
                                                newBlog.startingHour = ("0" + e.target.value).slice(-2) + "." + ("0" + this.state.chosenMinute).slice(-2) + "u";
                                                this.setState({ chosenHour: e.target.value, newBlog });
                                            }} />
                                        </div>
                                        <div className={dashboardstyles.colLg1}>
                                            <input type="number" step="1" min="00" max="59" value={this.state.chosenMinute} className={dashboardstyles.formControl} onChange={(e) => {
                                                let newBlog = { ...this.state.newBlog };
                                                newBlog.startingHour = ("0" + this.state.chosenHour).slice(-2) + "." + ("0" + e.target.value).slice(-2) + "u";
                                                this.setState({ chosenMinute: e.target.value, newBlog });
                                            }} />
                                        </div>
                                    </div>
                                </div>

                                {this.state.message !== "" && <p className={this.state.messageClass}>{this.state.message}</p>}
                                <span onClick={() => this.saveChanges("")} className={dashboardstyles.button}>Opslaan</span>
                            </form>
                        </div>
                    </div>
                }
                {this.state.section === Section.ManageBlogPosts &&
                    <div className={dashboardstyles.block}>
                        <div className={styles.width100}>
                            <h4>nieuwsberichten beheren</h4>
                            <p>Klik een blogpost aan om deze te bewerken. Klik op het kruisje rechts om een nieuwsbericht te verwijderen.</p>
                            {!this.state.tableId ?
                                <div>
                                    {this.state.blogs.map((blog, i) => {
                                        return (
                                            <div key={i} className={[dashboardstyles.bgDarkBlockNoPadding, dashboardstyles.clickableEntity].join(' ')}>
                                                <div onClick={() => this.openBlogForEdit(blog)} className={dashboardstyles.colLg11}><p>{blog.title}</p></div>
                                                <div onClick={() => this.removeBlog(blog._id)} className={dashboardstyles.colLg1 + " " + dashboardstyles.textAlignRight + " " + dashboardstyles.cross}><p>&#10005;</p></div>
                                            </div>
                                        );
                                    })}
                                    {this.state.blogs.length === 0 &&
                                        <div key={11} className={dashboardstyles.bgDarkBlock}>
                                            <div className={dashboardstyles.colLg11}><p style={{ color: "white" }}>U heeft nog geen nieuwsberichten. Klik linksboven op de knop 'Nieuw nieuwsbericht' om een nieuw bericht aan te maken!</p></div>
                                        </div>
                                    }
                                </div> :
                                <>
                                    <span className={dashboardstyles.backButton} onClick={() => this.setState({ tableId: "", locationAttached: false })}>
                                        <FontAwesomeIcon icon={faArrowLeft as any} /> Terug naar het overzicht
                                    </span>
                                    <ManageBlog
                                        blogPost={this.state.newBlog}
                                        changeInfo={this.changeInfo}
                                        editor={editor}
                                        config={config}
                                        uploadImage={uploadImage}
                                        toggleLocationAttached={this.toggleLocationAttached}
                                        locationAttached={this.state.locationAttached}
                                        chosenDate={this.state.chosenDate}
                                        chosenHour={this.state.chosenHour}
                                        setChosenHour={this.setChosenHour}
                                        chosenMinute={this.state.chosenMinute}
                                        setChosenMinute={this.setChosenMinute}
                                        // hourOptions={this.state.hourOptions}
                                        // minuteOptions={this.state.minuteOptions}
                                        updateDate={this.updateDate}
                                        message={this.state.message}
                                        messageClass={this.state.messageClass}
                                        saveChanges={this.saveChanges}
                                    />
                                </>

                            }

                        </div>
                    </div>
                }
            </div>
        </>
    }
}