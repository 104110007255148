import React from "react";
import dashboardstyles from "./../Dashboard.module.scss";
import styles from "./ManageEvents.module.scss";
import axios from "axios";
import "react-toggle/style.css"; // for ES6 modules
import 'react-dropdown/style.css';
import { DataHelper } from "../../../data/DataHelper";
import ManageEvent from "./ManageEvent";
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { EventProps } from "../../events/Events";

const EventTypes: string[] = ["Training/Les","Feest","Activiteit","Dansavond","Jeugd"];

enum Section {
    newEventPost,
    ManageEventPosts,
    None
}

export interface Info {
    key: string;
    value: string;
}

export interface IManageEventsProps {
    datahelper: DataHelper;
}

export interface IManageEventsState {
    section: Section;
    events: EventProps[];
    filteredEvents: EventProps[];
    newEvent: EventProps;
    tableId: string;
    message: string;
    messageClass: string;
    locationAttached: boolean;
    chosenDate: Date;
    chosenHour: string;
    chosenMinute: string;
    chosenEndHour: string;
    chosenEndMinute: string;
    chosenEventType: string;
}

export class ManageEvents extends React.Component<IManageEventsProps, IManageEventsState> {

    constructor(props: any) {
        super(props);
        this.state = { chosenDate: new Date(), chosenEventType:"Training/Les" , chosenHour: '00', chosenEndHour: '00', chosenMinute: '00', chosenEndMinute: '00', section: Section.None, locationAttached: false, events: [], filteredEvents: [], newEvent: this.returnNewEvent(), tableId: "", message: "", messageClass: "" };
    }

    public returnNewEvent() {
        return { title: "", eventDate: this.props.datahelper.formatDate(new Date()), startingHour: "00.00u", endingHour: "00.00u", location: "Geen locatie", type: "Training/Les" };
    }

    public toggleLocationAttached = () => {
        this.setState({ locationAttached: !this.state.locationAttached });
    }

    public setChosenHour = (chosenHour: string) => {
        let newEvent = { ...this.state.newEvent };
        newEvent.startingHour = ("0" + chosenHour).slice(-2) + "." + ("0" + this.state.chosenMinute).slice(-2) + "u";
        this.setState({ chosenHour, newEvent });
    }

    public setChosenMinute = (chosenMinute: string) => {
        let newEvent = { ...this.state.newEvent };
        newEvent.startingHour = ("0" + this.state.chosenHour).slice(-2) + "." + ("0" + chosenMinute).slice(-2) + "u";
        this.setState({ chosenMinute, newEvent });
    }

    public setChosenEndHour = (chosenHour: string) => {
        let newEvent = { ...this.state.newEvent };
        newEvent.endingHour = ("0" + chosenHour).slice(-2) + "." + ("0" + this.state.chosenEndMinute).slice(-2) + "u";
        this.setState({ chosenEndHour: chosenHour, newEvent });
    }

    public setChosenEndMinute = (chosenMinute: string) => {
        let newEvent = { ...this.state.newEvent };
        newEvent.endingHour = ("0" + this.state.chosenEndHour).slice(-2) + "." + ("0" + chosenMinute).slice(-2) + "u";
        this.setState({ chosenEndMinute:  chosenMinute, newEvent });
    }

    public setChosenEventType = (eventType) => {
        let newEvent = { ...this.state.newEvent };
        newEvent.type = eventType;
        this.setState({ newEvent });
    }

    public updateDate = (e: Date) => {
        let dateString = this.props.datahelper.formatDate(e);
        let newEvent = { ...this.state.newEvent };
        newEvent.eventDate = dateString;
        this.setState({ chosenDate: e, newEvent });
    }

    public componentDidMount() {
        this.getData();
    }

    public getData = () => {
        axios.get('/events/').then(res => {
            let events = res.data?.map(event => ({
                _id: event._id,
                createdAt: event.createdAt,
                endingHour: event.endingHour,
                eventDate: event.eventDate,
                location: event.location,
                startingHour: event.startingHour,
                title: event.title,
                type: event.type,
                updatedAt: event.updatedAt,
                creationDate: new Date(event.createdAt)
            })).sort((objA, objB) => objB.creationDate.getTime() - objA.creationDate.getTime());

            let newEvent = { ...this.state.newEvent };
            
            this.setState({ events, filteredEvents:events, newEvent });
        });
    }

    public activate = (section: Section) => {
        this.setState({ section, message: "", messageClass: "", newEvent: this.returnNewEvent(), locationAttached: false, chosenDate: new Date(), chosenHour: "00", chosenMinute: "00", chosenEndHour:"00", chosenEndMinute:"00", tableId: "" });
    }

    public changeInfo = (attribute: string, value: string) => {
        let newEvent = { ...this.state.newEvent };
        newEvent[attribute] = value;
        this.setState({ newEvent });
    }

    public saveChanges = (_id: string) => {
        if (this.state.section === Section.ManageEventPosts) {
            let tableId = this.state.tableId;
            let newEvent = { ...this.state.newEvent };
            
            if (newEvent.endingHour &&
                newEvent.eventDate &&
                newEvent.location &&
                // newEvent.location !== "Geen locatie" && 
                newEvent.startingHour &&
                newEvent.title &&
                newEvent.type) {
                axios.put('/events/' + tableId, newEvent).then(res => {
                    let message = "";
                    let messageClass = "";
                    if (res.status === 200) {
                        message = "Uw event werd gewijzigd!";
                        messageClass = dashboardstyles.success;
                    } else {
                        message = "Er ging iets mis.";
                        messageClass = dashboardstyles.error;
                    }
                    this.setState({ message, messageClass }, () => this.getData());

                });
            } else {
                Swal.fire('Oeps!', 'Eén of meerdere verplichte velden werden niet ingevuld', 'warning');
            }
        } else if (this.state.section === Section.newEventPost) {
            try {
                let newEvent = { ...this.state.newEvent };
                if (newEvent.endingHour &&
                    newEvent.eventDate &&
                    newEvent.location &&
                    newEvent.location !== "Geen locatie" && 
                    newEvent.startingHour &&
                    newEvent.title &&
                    newEvent.type) {
                    axios.post('/events/', newEvent).then(res => {
                        let message = "";
                        let messageClass = "";
                        if (res.status === 200) {
                            this.setState({ newEvent: this.returnNewEvent(), 
                            chosenHour: "00", chosenEndHour: '00', chosenMinute: "00", chosenEndMinute: '00'})
                            message = "Uw aanpassingen werden opgeslagen!";
                            messageClass = dashboardstyles.success;

                        } else {
                            message = "Er ging iets mis.";
                            messageClass = dashboardstyles.error;
                        }
                        this.setState({ message, messageClass }, () => this.getData());
                    });
                } else {
                    Swal.fire('Oeps!', 'Eén of meerdere verplichte velden werden niet ingevuld', 'warning');
                }
            } catch (err) {
                console.error(err);
            }
        }
    }

    public openEventForEdit = (eventPost: EventProps) => {
        let locationAttached = !(!eventPost.location || eventPost.location === "");
        let date = new Date();
        let dateString = "";
        if (eventPost.eventDate) {
            dateString = eventPost.eventDate.split("/")[1] + "/" + eventPost.eventDate.split("/")[0] + "/" + eventPost.eventDate.split("/")[2];
            date = new Date(dateString);
        }
        const hour = eventPost.startingHour.split(".")[0];
        const minute = eventPost.startingHour.split(".")[1].slice(0, 2);
        const endHour = eventPost.endingHour.split(".").length > 0 ? eventPost.endingHour.split(".")[0] : "00";
        const endMinute = eventPost.endingHour.split(".").length > 1 ? eventPost.endingHour.split(".")[1].slice(0, 2) : "00";

        this.setState({ tableId: eventPost._id ?? "", newEvent: eventPost, locationAttached, chosenDate: date, chosenHour: hour, chosenMinute: minute, chosenEndHour: endHour, chosenEndMinute: endMinute , chosenEventType: eventPost?.type });
    }

    public removeEvent = (_id: string) => {
        Swal.fire({
            title: 'Verwijderen bevestigen',
            text: 'Weet u zeker dat u dit nieuwsbericht wil verwijderen?',
            icon: 'error',
            confirmButtonText: 'OK'
        }).then(confirm => {
            if (confirm.isConfirmed) {
                let message = "";
                let messageClass = "";
                let newEvent = { title: "", eventDate: "", location: "Geen locatie", type: "Training/Les", startingHour: "00.00u", endingHour: "00.00u" };
                if (_id && _id !== "") {
                    axios.delete('/events/' + _id).then(res => {
                        if (res.status === 200) {
                            message = "Uw event werd Verwijderd!";
                            messageClass = dashboardstyles.success;
                        } else {
                            message = "Er ging iets mis.";
                            messageClass = dashboardstyles.error;
                        }
                        this.setState({ tableId: "", section: Section.ManageEventPosts, message, messageClass, newEvent, locationAttached: false, chosenDate: new Date(), chosenHour: '00', chosenMinute: '00' }, () => this.getData());
                    });
                }
            }
        });
    }

    public searchEvents = (query) => {
        let events = [...this.state.events];
        let filteredEvents = events.filter(e => e.title.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        e.location.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        e.eventDate.toLowerCase().indexOf(query.toLowerCase()) !== -1)
        this.setState({ filteredEvents });
    }

    render() {
        return <>
            <div className={dashboardstyles.colLg3}>
                <div className={dashboardstyles.block}>
                    <div className={styles.width100}>
                        <h3>Maak een keuze</h3>
                        <p>Klik op één van onderstaande knoppen om een keuze te maken</p>
                        <div className={styles.selectInfo}>
                            <div onClick={() => this.activate(Section.newEventPost)} className={[dashboardstyles.bgDarkBlock, styles.selectItem, this.state.section === Section.newEventPost ? styles.selectedSection : ""].join(' ')}>
                                <h4>Nieuw evenement</h4>
                                <p>Klik hier om een evenement aan te maken</p>
                            </div>
                            <div onClick={() => this.activate(Section.ManageEventPosts)} className={[dashboardstyles.bgDarkBlock, styles.selectItem, this.state.section === Section.ManageEventPosts ? styles.selectedSection : ""].join(' ')}>
                                <h4>Evenementen beheren</h4>
                                <p>Klik hier om een bestaand evenement te wijzigen of verwijderen</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={dashboardstyles.colLg9} >
                {this.state.section === Section.newEventPost &&
                    <div className={dashboardstyles.block}>
                        <div className={styles.width100}>
                            <h4>Nieuw evenement toevoegen aan de kalender</h4>
                            <p>Vergeet niet om te klikken op "opslaan" wanneer je klaar bent!</p>
                            <ManageEvent
                                eventPost={this.state.newEvent}
                                changeInfo={this.changeInfo}
                                chosenDate={this.state.chosenDate}
                                chosenHour={this.state.chosenHour}
                                setChosenHour={this.setChosenHour}
                                chosenMinute={this.state.chosenMinute}
                                setChosenMinute={this.setChosenMinute}
                                chosenEndHour={this.state.chosenEndHour}
                                setChosenEndHour={this.setChosenEndHour}
                                chosenEndMinute={this.state.chosenEndMinute}
                                setChosenEndMinute={this.setChosenEndMinute}
                                eventTypes={EventTypes}
                                updateDate={this.updateDate}
                                chosenEventType={this.state.chosenEventType}
                                setChosenEventType={this.setChosenEventType}
                                message={this.state.message}
                                messageClass={this.state.messageClass}
                                saveChanges={this.saveChanges}
                            />
                        </div>
                    </div>
                }
                {this.state.section === Section.ManageEventPosts &&
                    <div className={dashboardstyles.block}>
                        <div className={styles.width100}>
                            <h4>Evenementen beheren</h4>
                            <p>Klik een evenement aan om deze te bewerken. Klik op het kruisje rechts om een nieuwsbericht te verwijderen.</p>
                            <form>
                                <div className={[dashboardstyles.row, dashboardstyles.formGroup].join(' ')}>
                                    <div className={dashboardstyles.colLg12}>
                                        <input onChange={(e) => this.searchEvents(e.target.value)} placeholder="Zoek op naam, datum, locatie,..." type="text" className={dashboardstyles.formControl} />
                                    </div>
                                </div>
                            </form>

                            {!this.state.tableId ?
                                <div>
                                    {this.state.filteredEvents.map((event, i) => {
                                        return (
                                            <div key={i} className={[dashboardstyles.bgDarkBlockNoPadding, dashboardstyles.clickableEntity].join(' ')}>
                                                <div onClick={() => this.openEventForEdit(event)} className={dashboardstyles.colLg9}><p>{event.title}</p></div>
                                                <div className={dashboardstyles.colLg2}><p style={{ fontWeight: 400 }}>{event.eventDate}</p></div>
                                                <div onClick={() => this.removeEvent(event._id ?? "")} className={dashboardstyles.colLg1 + " " + dashboardstyles.textAlignRight + " " + dashboardstyles.cross}><p>&#10005;</p></div>
                                            </div>
                                        );
                                    })}
                                    {this.state.filteredEvents.length === 0 &&
                                        <div key={11} className={dashboardstyles.bgDarkBlock}>
                                            <div className={dashboardstyles.colLg11}><p style={{ color: "white" }}>U heeft nog geen events. Klik linksboven op de knop 'Nieuw evenement' om een evenement aan te maken!</p></div>
                                        </div>
                                    }
                                </div> :
                                <>
                                    <span className={dashboardstyles.backButton} onClick={() => this.setState({ tableId: "", locationAttached: false })}>
                                        <FontAwesomeIcon icon={faArrowLeft as any} /> Terug naar het overzicht
                                    </span>
                                    <ManageEvent
                                        eventPost={this.state.newEvent}
                                        changeInfo={this.changeInfo}
                                        chosenDate={this.state.chosenDate}
                                        chosenHour={this.state.chosenHour}
                                        setChosenHour={this.setChosenHour}
                                        chosenMinute={this.state.chosenMinute}
                                        setChosenMinute={this.setChosenMinute}
                                        chosenEndHour={this.state.chosenEndHour}
                                        setChosenEndHour={this.setChosenEndHour}
                                        chosenEndMinute={this.state.chosenEndMinute}
                                        setChosenEndMinute={this.setChosenEndMinute}
                                        eventTypes={EventTypes}
                                        chosenEventType={this.state.chosenEventType}
                                        setChosenEventType={this.setChosenEventType}
                                        updateDate={this.updateDate}
                                        message={this.state.message}
                                        messageClass={this.state.messageClass}
                                        saveChanges={this.saveChanges}
                                    />
                                </>

                            }

                        </div>
                    </div>
                }
            </div>
        </>
    }
}