import styles from './login.module.scss';
import React, { Component } from 'react';
import Auth from './../../Auth';

export default class Login extends Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            username: "",
            password: "",
            message: "",
            messageClass: ""
        };

        this.login = this.login.bind(this);
    }

    public async login(e: { preventDefault: () => void; }): Promise<void> {

        e.preventDefault();

        Auth.login({
            username: this.state.username,
            password: this.state.password
        }, (loggedIn: boolean) => {
            if (loggedIn) {
                this.props.checkLogIn();
                // this.props.history.push('/');
                window.location.href = "/admin";
            } else {
                this.setState({ message: "Wrong password or username!", messageClass: styles.warning });
            }
        });

        // let authenticated = await auth.login({
        //     username: this.state.username,
        //     password: this.state.password
        // });
        // if (authenticated) {
        //     this.props.history.push("/");
        // }else {
        //     this.setState({message:"Wrong password or username!", messageClass:styles.warning});
        // }

    }

    render(): React.ReactElement {
        return (
            <div className={styles.login}>
                <div className={styles.loginForm}>
                    <h2>HAPPY DANCER ADMIN DASHBOARD</h2>
                    <div className={styles.messageHolder}>
                        <span className={this.state.messageClass}>{this.state.message}</span>
                    </div>
                    <form onSubmit={this.login}>
                        <input className={styles.field} type="text" placeholder="username" onChange={(e) => this.setState({ username: e.target.value })} />
                        <input className={styles.field} type="password" placeholder="password" onChange={(e) => this.setState({ password: e.target.value })} />
                        <input className={styles.submit} type="submit" value="LOGIN" />
                    </form>
                </div>
            </div>
        );
    }
}