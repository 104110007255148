import Fade from 'react-reveal/Fade';
import bannerImage from './../../assets/images/dancingchildren3.jpg';
import styles from './Homepage.module.scss';
import { useEffect, useState } from 'react';
import InstagramFeed from '../InstagramFeed/InstagramFeed';
import { BlogProps } from '../blogs/Blogs';
import Blog from '../blogs/blog/Blog';
import { NavLink, Link } from "react-router-dom";
import axios from 'axios';
import { DataHelper } from "../../data/DataHelper";

const defaultFeed: any[] = [
    {
        "caption": "💃🏼 Eerste les SALSA!\n\n•\n•\n•\n#salsa #eersteles #dansclubhappydancerham #dansclubhappydancer #happydancer #happydancerham #dansclub #dansclubkempen #dansclublimburg #dansclubham #ham #dance #dancing #danceschool",
        "media_type": "CAROUSEL_ALBUM",
        "permalink": "https://www.instagram.com/p/CjKpj_ToiEo/",
        "media_url": "https://scontent-bru2-1.cdninstagram.com/v/t51.29350-15/309615399_641881567469159_2116964052819843594_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=jbfd390ygq8AX8Rhgkf&_nc_ht=scontent-bru2-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT-tr-1pQAxIPBUS4UWTM8zHPARCoRg5x-qHGK-yZmqA_A&oe=634B6FE7",
        "id": "18322588459052766"
    },
    {
        "caption": "Wij kijken met veel trots terug naar een fantastische opendeurdag! Wij hebben er enorm van genoten 🥰\n\nBedankt voor jullie ongelooflijk enthousiasme, bedankt voor de mooie demonstraties en bedankt aan alle helpende handen 👏🏻 \n\nBEDANKT iedereen om van onze OPENDEURDAG een topeditie te maken! ❤️\n\n•\n•\n•\n#opendeur #opendeurdag #trots #genoten #bedankt #topeditie #dansclubhappydancerham #dansclubhappydancer #happydancer #happydancerham #dansclub #dansclubkempen #dansclublimburg #dansclubham #ham #dance #dancing #danceschool",
        "media_type": "CAROUSEL_ALBUM",
        "permalink": "https://www.instagram.com/p/CiF-dKMok1V/",
        "media_url": "https://scontent-bru2-1.cdninstagram.com/v/t51.29350-15/305531881_1522106841539195_2010293909444594543_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=er8e-QjZwgoAX8gnmp6&_nc_ht=scontent-bru2-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT_3Xc_3U7DUHoa_G_KilgTEjLVG8pjsyNzAwLeNyUpdrQ&oe=634BA008",
        "id": "17955303701068601"
    },
    {
        "caption": "Wij kijken met veel trots terug naar een fantastische opendeurdag! Wij hebben er enorm van genoten 🥰\n\nBedankt voor jullie ongelooflijk enthousiasme, bedankt voor de mooie demonstraties en bedankt aan alle helpende handen 👏🏻 \n\nBEDANKT iedereen om van onze OPENDEURDAG een topeditie te maken! ❤️\n\n•\n•\n•\n#opendeur #opendeurdag #trots #genoten #bedankt #topeditie #dansclubhappydancerham #dansclubhappydancer #happydancer #happydancerham #dansclub #dansclubkempen #dansclublimburg #dansclubham #ham #dance #dancing #danceschool",
        "media_type": "CAROUSEL_ALBUM",
        "permalink": "https://www.instagram.com/p/CiF-IXooYK1/",
        "media_url": "https://scontent-bru2-1.cdninstagram.com/v/t51.29350-15/305008791_3288530481434828_8528178229416031761_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=aY3SkLID2pIAX-Zexg8&_nc_ht=scontent-bru2-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT9OKYxi0PZqgw32_WmLRrMj7-EC55CgD4Qx8Jc9gn0tRw&oe=634B37DA",
        "id": "17939586533181215"
    },
    {
        "caption": "Een prachtige uitvoering van ons formatieteam in Ballroom op de opendeurdag! 💃🏼 \n\n•\n•\n•\n#opendeur #opendeurdag #formatieteamballroom #formatieteam #ballroom #prachtigeuitvoering #dansclubhappydancerham #dansclubhappydancer #happydancer #happydancerham #dansclub #dansclubkempen #dansclublimburg #dansclubham #ham #dance #dancing #danceschool",
        "media_type": "CAROUSEL_ALBUM",
        "permalink": "https://www.instagram.com/p/CiF8LPZoUj1/",
        "media_url": "https://scontent-bru2-1.cdninstagram.com/v/t51.29350-15/304839248_757287942014612_1317936670487687955_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=Yd0rW6MNt_4AX_nGsN_&_nc_ht=scontent-bru2-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AT_1XZZgtfpMlwoirOS-vurRuucM4U27EfrxhIqogXXoBw&oe=634B32EA",
        "id": "18067353430316372"
    }
];

export interface IHomepageProps {
    datahelper: DataHelper;
}
function Homepage(props: IHomepageProps) {
    let emptyArr: any[] = [];
    const [instaFeed, setInstafeed] = useState(emptyArr);
    const [blogs, setBlogs] = useState<BlogProps[]>([]);

    useEffect(() => {
        const token = "IGQWRQWXFXRmZAqSG4wc3AwU2pmdnNxVGVtSFdrOUU5Q3pxUUNsb2VLOHhrTWJ5UDViS1pzdFFrYVhZAWVNDcVNYWnNHSy10dTVXTVBlUWRaWXBmU2x0a2J4N3JOcGtPWWItVDdReERVX0N3b1puZAEVWVXIydFItMTgZD";
        const url = "https://graph.instagram.com/me/media?fields=caption,media_count,media_type,permalink,media_url&&access_token=" + token;

        try {
            fetch(url).then(function (response) {
                return response.json();
            }).then(data => {
                setInstafeed(data.data);
            }).catch(oef => {
                setInstafeed(defaultFeed);
                console.error(oef);
            });
        } catch (err) {
            setInstafeed(defaultFeed);
            console.error(err);
        }

        axios.get('/blogs/').then(res => {
            let blogsTemp = props.datahelper.sortByDateStringDescending(res.data);
            let blogs = blogsTemp.length > 2 ? blogsTemp.slice(-2) : blogsTemp;
            setBlogs(blogs);
        });
    }, [props.datahelper]);
    return (

        <div className={styles.homepage}>
            <div style={{ position: "relative" }}>
                <img className={styles.bannerImg} alt="bannerImage" src={bannerImage} />
                <div className={styles.overlay}>
                    <Fade duration={1500} bottom>
                        <section className={styles.mainInfo}>
                            <h1>Dansclub Happy Dancer</h1>
                            <p>Leer dansen op een aangename en ontspannen manier in de gezelligste club van uren in de omtrek!</p>
                            <NavLink to="/cursussen" className={styles.mainButton}>Ontdek onze cursussen</NavLink>
                        </section>
                    </Fade>
                </div>
            </div>
            <section className={styles.instaFeedWrapper}>
                <InstagramFeed feed={instaFeed} token={""} counter="6" />
            </section>
            <section className={styles.lastNews}>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-lg-12"}>
                            <div className={styles.titleMarkup}>
                                <h3>Lees hier de laatste nieuwtjes over onze dansclub</h3>
                                <h2>Nieuws</h2>
                            </div>
                        </div>
                        {props.datahelper.sortByDateStringAscending(blogs).map((blog, i) => (
                            <div key={i} className="col-lg-6">
                                <div className={styles.blogWrapper}>
                                    <Blog blog={blog} />
                                    <div className={styles.gradient}></div>
                                    <div className={styles.blogHover}>
                                        <Link to={"/nieuws/" + blog.id}>MEER LEZEN</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="col-lg-12">
                        <NavLink to="/nieuws" style={{ marginBottom: "5em" }} className={styles.readMore}>Alle nieuwsberichten</NavLink>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Homepage;