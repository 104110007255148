import styles from './Navbar.module.scss';
import logo from './../../assets/images/logo.png';
import { NavLink } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

function Navbar() {
    const [openMobileMenu, setOpenMobileMenu] = useState(false);

    const toggleMobileMenu = () => {
        setOpenMobileMenu(!openMobileMenu);
    }

    return (
        <div className={styles.navbarContainer}>
            <div className={styles.mobileMenu}>
                <NavLink to="/">
                    <Fade duration={1500} bottom>
                        <img alt="logo" src={logo} className={styles.logo} />
                    </Fade>
                </NavLink>
                <span className={styles.hideOnDesktop} onClick={() => toggleMobileMenu()}>
                    <FontAwesomeIcon icon={faBars as any} />
                </span>
            </div>
            <ul className={styles.topNav + " " + (openMobileMenu ? styles.mobileDisplayBlock : styles.mobileDisplayNone)}>
                <li>
                    <NavLink activeClassName={styles.underline} to="/over-ons">Over ons</NavLink>
                </li>
                <li>
                    <NavLink activeClassName={styles.underline} to="/cursussen">Cursussen</NavLink>
                </li>
                <li>
                    <NavLink activeClassName={styles.underline} to="/uurrooster">Uurrooster</NavLink>
                </li>
                <li>
                    <NavLink activeClassName={styles.underline} to="/kalender">Kalender</NavLink>
                </li>
                <li>
                    <NavLink activeClassName={styles.underline} to="/nieuws">Nieuws</NavLink>
                </li>
            </ul>
        </div>
    );
}

export default Navbar;