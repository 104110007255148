import './App.css';
import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";
import Homepage from './components/homepage/Homepage';
import Navbar from './components/navbar/Navbar';
import AboutUs from './components/aboutus/AboutUs';
import Schedule from './components/schedule/Schedule';
import Events from './components/events/Events';
import Courses from './components/courses/Courses';
import Blogs from './components/blogs/Blogs';
import Footer from './components/footer/Footer';
import PrivateRoute from './PrivateRoute';
import { Dashboard } from './components/dashboard/Dashboard';
import Login from './components/login/Login';
import auth from './Auth.js';
import { DataHelper } from './data/DataHelper';
import Privacy from './Privacy';

let datahelper:DataHelper = new DataHelper();

function App() {
  // const [admin, setAdmin] = useState(false);
  // const [user, setUser] = useState(null);

  const checkLogIn = () => {
    auth.checkLoggedIn((res:any) => {
      if (res) {
        // setUser(res);
        // setCallDone(true);
      } else {
        // setCallDone(true);
        auth.logout();
        window.location.href = "/";
      }
    });
  }
  const path = window.location?.pathname?.split('/')?.[1];

  return (
    <div className="App">

      <>
        <BrowserRouter basename={"/"} forceRefresh={true}>
          <div style={{ position: "relative" }}>
            { path.toLowerCase() !== "login" && path.toLowerCase() !== "admin" && <Navbar />}
            <div>
              <Switch>
                <Route exact path="/">
                  <Homepage datahelper={datahelper} />
                </Route>
                <Route path="/cursussen">
                  <Courses datahelper={datahelper}/>
                </Route>
                <Route path="/over-ons">
                  <AboutUs />
                </Route>
                <Route path="/uurrooster">
                  <Schedule />
                </Route>
                <Route path="/kalender">
                  <Events />
                </Route>
                <Route path="/privacy-policy">
                  <Privacy />
                </Route>
                <Route exact path="/nieuws">
                  <Blogs datahelper={datahelper}/>
                </Route>
                <Route path="/nieuws/:id">
                  <Blogs datahelper={datahelper} />
                </Route>
                <Route path="/login" exact component={() => (
                  <Login checkLogIn={checkLogIn} />
                )} />
                <PrivateRoute path="/admin" exact component={() => (
                  <Dashboard />
                )} />
              </Switch>
            </div>
            { path.toLowerCase() !== "login" && path.toLowerCase() !== "admin" && <Footer />}
            
          </div>

          {/* <Footer /> */}
        </BrowserRouter>
      </>

    </div>
  );
}

export default App;
